import React from 'react';
import {TextInput, Box} from 'grommet';
import {
  FormClose,
  FormCheckmark,
  FormClock,
  DocumentTime
} from 'grommet-icons';

import styled from 'styled-components';
import {paleGrey, brand as brandGreen} from '../../utils/colors';

const StyledTextInput = styled(TextInput)`
  border-color: ${paleGrey};
  margin-top: '0.5em';
  :focus {
    border-color: ${brandGreen};
    border-width: 0.2em;
  }
`;

const StyledContainer = styled.div`
  margin-top: 1rem;
`;

const TextInputOrder = ({
  onChange,
  value,
  name,
  placeholder,
  error,
  loading,
  ...rest
}) => {
  return (
    <StyledContainer>
      <Box
        width="large"
        direction="row"
        align="center"
        pad={{horizontal: 'small', vertical: 'xsmall'}}
        round="small"
        border={{
          side: 'all',
          color: error ? 'accent-2' : 'border'
        }}
      >
        {loading && <DocumentTime color="brand" />}

        {!loading && error && <FormClose color="accent-2" />}
        {!loading && value !== '' && !error && <FormCheckmark color="brand" />}
        {!loading && value === '' && !error && <FormClock color="brand" />}

        <StyledTextInput
          id={name}
          name={name}
          placeholder={placeholder}
          plain
          value={value}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          {...rest}
        />
      </Box>
    </StyledContainer>
  );
};

export default TextInputOrder;
