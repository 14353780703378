import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';
import {Box} from 'grommet';
import {TextInput, Button, Banner} from '..';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import * as PropTypes from 'prop-types';

class SendPasswordForm extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired
    })
  };

  onSubmitLoginForm = async ({fields: {email}}) => {
    const {passwordMutation} = this.props;

    try {
      const {data} = await passwordMutation({
        variables: {email: email.value}
      });

      if (data.changePassword) {
        return [
          {
            message: 'Please check your e-mail.'
          }
        ];
      } else {
        // return error message
        return [{message: 'Unable to send password token'}];
      }
    } catch (error) {
      return error.graphQLErrors;
    }
  };

  render() {
    const {banner} = this.props;
    return (
      <FormState
        validateOnSubmit
        initialValues={{
          email: ''
        }}
        onSubmit={this.onSubmitLoginForm}
        validators={{
          email(input) {
            if (input === '') {
              return "E-mail can't be blank";
            }
            if (!/\S+@\S+\.\S+/.test(input)) {
              return 'Invalid e-mail';
            }
            return null;
          }
        }}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 ? (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              ) : (
                banner && <Banner status="critical">{banner}</Banner>
              )}
              <TextInput
                label="Email"
                placeholder="Enter your account e-mail"
                {...fields.email}
              />
              <Box direction="row" gap="small" margin={{top: 'small'}}>
                <Button loading={submitting} submit primary>
                  Send
                </Button>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default graphql(
  gql`
    mutation PasswordMutation($email: String!) {
      changePassword(email: $email)
    }
  `,
  {
    name: 'passwordMutation'
  }
)(SendPasswordForm);
