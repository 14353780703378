import React from 'react';
import {Page} from '..';
import InvoicesCard from './InvoicesCard';

const InvoicesPage = () => {
  return (
    <Page title="Invoices">
      <InvoicesCard />
    </Page>
  );
};

export default InvoicesPage;
