import React from 'react';
import {Text, Box} from 'grommet';
import {Link} from 'react-router-dom';
import {Card} from '..';

const KBCard = ({category}) => {
  const contents = [];

  category.articles.forEach((article, id) => {
    contents.push(
      <Link key={`art-${id}`} to={`/kb/${article.id}`}>
        <Box pad={{horizontal: 'small', vertical: 'xsmall'}}>
          <Text size="small">{article.title}</Text>
        </Box>
      </Link>
    );
  });
  return (
    <Card fill>
      <Text>{category.name}</Text>
      {contents}
    </Card>
  );
};

export default KBCard;
