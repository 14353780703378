import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';

import {Select, TextInputOrder, Button, Banner, Spinner} from '..';
import {Box} from 'grommet';
import axios from 'axios';
import gql from 'graphql-tag';
import {graphql, compose} from 'react-apollo';

import * as PropTypes from 'prop-types';

import {API_CHECK_USERNAME} from '../../config';

import './BuyVPNForm.css';

class LoginForm extends Component {
  state = {
    working: false,
    linking: false,
    redirecting: false,
    usernameError: false,
    usernameChecking: false,
    usernameTimer: null,
    passwordValid: false,
    passwordError: false,
    password2Error: false,
    country: 'US'
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired
    })
  };

  onSubmitLoginForm = async ({
    fields: {email, plan, processor, username, password}
  }) => {
    const {subscriptionMutation, paymentMutation} = this.props;
    const {passwordValid, country} = this.state;

    if (!passwordValid) {
      return [{message: 'Please provide a valid password.'}];
    }

    try {
      this.setState({working: true});
      const {data} = await subscriptionMutation({
        variables: {
          subscription: {
            plan: plan.value,
            processor: processor.value,
            country,
            email: email.value,
            username: username.value,
            password: password.value
          }
        }
      });

      if (data.createSubscription && data.createSubscription.invoiceId) {
        window.localStorage.setItem('jwt', data.createSubscription.token);
        this.setState({working: false, linking: true});

        // we have our invoiceId now build the link

        const link = await paymentMutation({
          variables: {
            invoiceId: data.createSubscription.invoiceId
          }
        });

        this.setState({working: false, linking: false, redirecting: true});

        if (link && link.data && link.data.getPaymentLink) {
          window.top.location.href = link.data.getPaymentLink;
        } else {
          return [{message: 'Unable to complete your order'}];
        }

        // redirect to the pay page
        // this.context.router.history.push(data.createSubscription.url);
        // window.top.location.href = data.createSubscription.url;
      } else {
        // return error message
        return [{message: 'Unable to complete your order'}];
      }
    } catch (error) {
      this.setState({working: false, linking: false, redirecting: false});
      return error.graphQLErrors;
    }
  };

  validatePassword(password, password2) {
    if (password.length < 6) {
      this.setState({
        passwordError: 'Password require 6 characters minumum.'
      });
    } else {
      this.setState({
        passwordError: false
      });
    }

    if (password === password2) {
      this.setState({
        passwordValid: true,
        passwordError: false,
        password2Error: false
      });
    } else if (password2.length > 0) {
      this.setState({
        password2Error: 'Password do not match'
      });
    } else {
      this.setState({
        password2Error: false
      });
    }
  }

  validateUsername(username) {
    const {usernameTimer} = this.state;

    this.setState({
      usernameError: false,
      usernameChecking: true,
      usernameValid: false
    });

    if (username.length < 5) {
      this.setState({
        usernameError: 'Username require 5 characters minumum.',
        usernameTimer: null,
        usernameValid: false
      });
      return;
    }

    // contains only azAZ09
    if (!/^[a-zA-Z0-9]+$/.test(username)) {
      this.setState({
        usernameError: 'Invalid username, use alphanumeric characters only.',
        usernameTimer: null,
        usernameValid: false
      });
      return;
    }
    // did we have long enough username ?

    if (usernameTimer) {
      clearTimeout(usernameTimer);
    }

    const timer = setTimeout(async () => {
      const result = await axios.get(
        API_CHECK_USERNAME.replace(/%s/g, username)
      );
      if (result.data && result.data.valid === false) {
        this.setState({
          usernameError: 'Username is not available',
          usernameTimer: null,
          usernameValid: false
        });
      } else {
        this.setState({usernameValid: true});
      }

      this.setState({usernameChecking: false});
    }, 300);

    this.setState({usernameTimer: timer});
  }

  render() {
    const {
      usernameError,
      usernameChecking,
      usernameValid,
      passwordError,
      password2Error,
      passwordValid,
      redirecting,
      linking,
      working
    } = this.state;

    const selectedPlan = this.props.plan ? this.props.plan.toUpperCase() : null;
    const selectedProcessor = this.props.processor
      ? this.props.processor.toUpperCase()
      : null;

    let usernameHelpText;
    if (usernameError) {
      usernameHelpText = false;
    } else if (usernameChecking) {
      usernameHelpText = (
        <React.Fragment>
          <Spinner color="teal" size="xxsmall" />
        </React.Fragment>
      );
    } else if (usernameValid) {
      usernameHelpText = 'available';
    }

    return (
      <FormState
        validateOnSubmit
        initialValues={{
          email: '',
          plan: selectedPlan || 'ANNUALLY',
          processor: selectedProcessor || 'PAYPAL',
          username: '',
          password: '',
          password2: ''
        }}
        onSubmit={this.onSubmitLoginForm}
        validators={{
          email(input) {
            if (!/\S+@\S+\.\S+/.test(input)) {
              return 'Invalid e-mail';
            }
            if (input === '') {
              return "E-mail can't be blank";
            }
            return null;
          },
          // eslint-disable-next-line
          username(input) {
            if (input === '') {
              return "Username can't be blank";
            }
            return null;
          }
        }}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          // const firstError = fields.find((field) => field.error);
          console.log(errors);

          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 && (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              )}

              {!selectedPlan && (
                <Select
                  label="Plan"
                  options={[
                    {name: 'Monthly', id: 'MONTHLY'},
                    {name: 'Annually', id: 'ANNUALLY'}
                  ]}
                  {...fields.plan}
                />
              )}

              {!selectedProcessor && (
                <Select
                  label="Payment processor"
                  options={[
                    {name: 'Paypal', id: 'PAYPAL'},
                    {name: 'Paymentwall', id: 'PAYMENTWALL'},
                    {name: 'Crypto', id: 'CRYPTO'}
                  ]}
                  {...fields.processor}
                />
              )}

              <TextInputOrder
                label="E-mail"
                placeholder="Enter your e-mail address"
                {...fields.email}
                error={
                  !/\S+@\S+\.\S+/.test(fields.email.value) &&
                  fields.email.value !== ''
                }
              />

              <TextInputOrder
                label="Username"
                placeholder="Enter your VPN username"
                {...fields.username}
                id={fields.username.name}
                error={usernameError && fields.username.value !== ''}
                helpText={usernameHelpText}
                loading={usernameChecking && fields.username.value !== ''}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(username) => {
                  fields.username.onChange(username);
                  this.validateUsername(username);
                }}
              />

              <TextInputOrder
                label="Password"
                placeholder="Enter your VPN.ht password"
                type="password"
                id={fields.username.password}
                {...fields.password}
                error={passwordError}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(password) => {
                  fields.password.onChange(password);
                  this.validatePassword(password, fields.password2.value);
                }}
              />

              <TextInputOrder
                label="Confirm password"
                placeholder="Enter your VPN.ht password"
                type="password"
                id={fields.username.password2}
                {...fields.password2}
                error={password2Error}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(password) => {
                  fields.password2.onChange(password);
                  this.validatePassword(fields.password.value, password);
                }}
              />
              <Box gap="medium" direction="row" justify="start" align="start">
                <Box align="start" fill>
                  <div className="order-summary">
                    <div className="total">
                      Order Total:{' '}
                      <span className="order-total-value">
                        ${fields.plan.value === 'MONTHLY' ? '1.00' : '39.99'}
                      </span>
                    </div>
                    <div className="billing-cycle">
                      {fields.plan.value === 'MONTHLY'
                        ? 'For the 1st month, then $4.99 / month'
                        : 'Billed every 12 months.'}
                    </div>

                    {working ? (
                      <Button submit primary size="large" round="12px" disabled>
                        Creating account...
                      </Button>
                    ) : linking ? (
                      <Button submit primary size="large" round="12px" disabled>
                        Building link...
                      </Button>
                    ) : redirecting ? (
                      <Button submit primary size="large" round="12px" disabled>
                        Redirecting...
                      </Button>
                    ) : (
                      <Button
                        loading={submitting}
                        submit
                        primary
                        size="large"
                        round="12px"
                        disabled={!usernameValid || !passwordValid}
                      >
                        Join now
                      </Button>
                    )}
                  </div>
                </Box>

                <Box align="end">
                  <img
                    className="bottomImage"
                    src="https://vpn.ht/themes/vpnht/assets/www2/images/monster-join.png"
                    alt="VPN.ht Monsters"
                  />
                </Box>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default compose(
  graphql(
    gql`
      mutation SubscriptionMutation($subscription: SubscriptionInput!) {
        createSubscription(subscription: $subscription) {
          invoiceId
          url
          token
        }
      }
    `,
    {
      name: 'subscriptionMutation'
    }
  ),
  graphql(
    gql`
      mutation GetPaymentMutation($invoiceId: ID!) {
        getPaymentLink(invoiceId: $invoiceId)
      }
    `,
    {
      name: 'paymentMutation'
    }
  )
)(LoginForm);
