import React from 'react';
import {AccountDeletion, AppWrapper} from '../components';

const AccountDeletionRoute = () => {
  return (
    <AppWrapper noFrame>
      <AccountDeletion />
    </AppWrapper>
  );
};

export default AccountDeletionRoute;
