import React, {Component} from 'react';

import * as PropTypes from 'prop-types';

import PayComponent from './PayComponent';

class PayPage extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired
    })
  };

  redirectTo(link) {
    this.context.router.history.push(link);
  }

  render() {
    const {invoiceId} = this.props;

    let {processor} = this.context.router.route.match.params;
    const {type} = this.context.router.route.match.params;
    // can be overwritten by url param
    let shouldSubscribe = true;

    if (processor) {
      processor = processor.toUpperCase();
    }

    if (type === 'one-time') {
      shouldSubscribe = false;
    }

    return (
      <PayComponent
        invoiceId={invoiceId}
        subscription={shouldSubscribe}
        processor={processor}
      />
    );
  }
}

export default PayPage;
