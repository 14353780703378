import React from 'react';

export const Monster1 = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 560"
    enableBackground="new 0 0 288 560"
    style={{width: '48px'}}
  >
    <g>
      <path
        fill="#8AC876"
        d="M76,212.2c0,0-6.8,15.2,0.5,29.8s15.5,25.8,16.2,26.8s10.8,26,10.2,27.8s-4,8-4,8l-11.2,19
		c0,0-8,7.8-4.5,14s4.2,5.5,5.8,5.8s2.8-0.5,3.5-0.2s12.5,16.2,12.5,16.2s-0.2,8.8,0.8,8.8s7.2,16,33.2,18.8s37.3-2.8,46.2-15.5
		c8.8-12.7,7.2-26.7,7.2-27.2s-2.2-24.8-2.2-24.8s5.7,3.7,6,6.3c0.3,2.7-0.2,5.3-1,6c-0.8,0.7-4.3,3.3-6.3,3.7s-0.5,5.3,0.5,5.3
		s9.5-1.3,11.5-11.3s-3.3-13-3.3-13l-7-5.5c0,0-1.2-18.7,4.8-33.5s20.3-27.5,23.8-37.2c3.5-9.7,4.3-24.3-3.2-38
		c-7.5-13.7-33.7-44.2-51.2-49.2s-28.3-3.8-42.3,4s-27.2,22-35.3,33.7C79,202.3,76,212.2,76,212.2z"
      />
      <path
        fill="#5AAC6D"
        d="M105.2,169.2c0,0,35.2-17,45.8,26.2s-14.2,52.8-13,78.2s7.8,38,1.8,55.5s-5,27.5,0.8,33s5.8,26.2-11.4,22.5
		s6.5,3.4,22.4,2.9s26.4-3.8,32-13.4s8.5-23.6,8.5-23.6l0.2-8.1c0,0,3.9-3,6.1-6.5s3.4-12.1,1.8-14.9s-10.6-9.9-10.6-9.9
		s0.5,6.5,0.8,6.9s5.6,3.8,5.5,9.1s-2.4,5.9-2.4,5.9s-1.2,1.4-1.4,0.2s-1.4-12.9-1.4-12.9l-0.4-12.1c0,0,0.5-13.4,1.6-18.1
		s3.8-15.9,9.1-23.9s17.9-19.6,20-35.5s-4.8-27.2-4.8-27.2s-15.4-27-30-36.6s-22.9-17.6-43.6-16.2S108.9,164,105.2,169.2z"
      />
      <path
        fill="#5AAC6D"
        d="M85.4,192.9l5.5,0.5c0,0,12.4-26.5,41.9-27.9s-21.4,26.1-21.4,26.1S92,206.9,91.1,206.4s-11.9-6.5-11.5-6.6
		S85.4,192.9,85.4,192.9z"
      />
      <path
        fill="#5AAC6D"
        d="M102,302.2c0,0,16.1,3.5,16.1,6s-0.2,4.2,2.9,4.6s19.6,4.4,21.9,1.6s16.2,9.4,3,9.9s-35-2.2-36.4-3.2
		s-9.6-8.2-9.8-9s-1.9-8-1.9-8S101.8,299.9,102,302.2z"
      />
      <path
        fill="#5AAC6D"
        d="M89.4,325.3c-0.3,0.4-5.1,8.2-2.7,7.4s4-4.9,5.7-2.5s-4.6,4.1-2.8,5.8s5.9,2,5.6,4.2s-2.9,2.2-2.7,2.7
		s7.4,2.9,11-2.7s-0.4-16.8-0.4-16.8S92.6,320.5,89.4,325.3z"
      />
      <path
        fill="#8AC876"
        d="M179.7,337.6c0.4,0-4,0.4-3.8,3c0.2,2.6,1.8,2.6,1.8,2.6s4.1,1.5,1.7,5c-2.4,3.5-6.6,2.7-6.3,2.2
		s5-5.9,3.7-6.4c-1.3-0.5-6.9-1.5-6.3-3.2c0.6-1.7,2.9-2.5,3.2-2.5S174.8,337.2,179.7,337.6z"
      />
      <path
        fill="#8AC876"
        d="M188.9,317.3c0,0,1.3-2.1,3.2-1.3c1.8,0.8,8.8,4.4,5.8,13s-11,5.8-11,5.8s8.6,0.1,9-6.9
		c0.4-7-7.2-10.7-7.2-10.7"
      />
      <path
        fill="#B3D792"
        d="M76.5,243.2c0,0,5.8,1.3,12.5,11.3s14.3,24.7,14.3,31.8s-1.5,6-1.3,5s-4.5-12.5-9.7-21.3
		S76.5,243.2,76.5,243.2z"
      />
      <path
        fill="#B3D792"
        d="M83.3,331.3c0,0,3.5,3.5,3.3,6.5s-3.2,1.7-3,0.7S81.7,335,83.3,331.3z"
      />
      <path
        fill="#3F915D"
        d="M120.1,262.5c0,0-5,0.9-4.6,11.5s10.9,20,21.8,23.1s36.5-9,36.5-9s7.2-8.9,7.9-19.4s-3-13.2-7.5-12.8
		s-4,0.9-4.8,1.2s-11.1,8.5-24.5,9.9s-17.8-2.1-18.4-2S121.1,261.8,120.1,262.5z"
      />
      <path
        fill="#FFFFFF"
        d="M123.8,263.8c0,0-4.2,8.5,4.6,12.8c8.8,4.2,13,0.2,14.6-2.6s2.6-6.4,2.6-6.4S136.4,268.8,123.8,263.8z"
      />
      <path
        fill="#FFFFFF"
        d="M153.7,265.8c-0.5,0.3,0.3,5,8.8,5.5c8.4,0.5,11.6-6.2,11.7-8.8c0.1-2.5-1.9-7.2-2-6.9
		c-0.1,0.2-5.6,2.7-5.6,2.7S156.2,264.7,153.7,265.8z"
      />
      <path
        fill="#E6E7E8"
        d="M133.2,267.4c0,0,1.4,3.7,0,6.8s-7.2,0.8-7,0.8s4.2,3.6,9.6,2.7c5.4-0.9,8.4-5.5,9.3-7.5
		c0.9-2,0.4-2.8,0.4-2.8S137.2,268.3,133.2,267.4z"
      />
      <path
        fill="#E6E7E8"
        d="M162.4,261.5c0,0,3.3,2.8,3,6.2c-0.3,3.4-2,3.5-1.7,3.5s5.3,1,8.7-5.2c3.4-6.2-0.2-9.1-0.3-9.5
		c-0.2-0.4,0.2-0.4-0.2-0.4C171.6,256.2,162.4,261.5,162.4,261.5z"
      />
      <path
        fill="#E987B2"
        d="M134,295.9c0,0,5.8-13.2,20.5-14.6s19.4,6,19.4,6s-15.7,18.5-39.8,8.9"
      />
      <path
        fill="#DE72A2"
        d="M145.5,283.9c0,0,6-1.2,10,5.5s-3.2,9.6-3.2,9.6s11.4-2.3,16.6-7.1c5.2-4.8,5.6-4.5,5.2-4.8
		C173.6,286.8,161.7,275.2,145.5,283.9z"
      />
      <path
        fill="#3A3A3A"
        d="M92,198.2c0,0-11.7-2.3-14.5,1.3s-2.7,37,2.5,39s13.5,0.8,13.5,0.8s11,21.7,37.7,25.5s39.7-12,43.3-17.7
		c3.7-5.7,5.3-8.2,5.3-8.2s11.7,1.7,14,0.7c2.3-1,2.7-2.3,2.7-3c0-0.7,3-17.3,1.3-27c-1.7-9.7-2-11-2-11s-2.2-1.2-3.5-1.2
		c-1.3,0-9,1-9,1s-10.5-28.3-39.3-30.2C115.2,166.5,100.3,178.2,92,198.2z"
      />
      <path
        fill="#58595B"
        d="M154.5,170.2c0,0-37.5-0.8-52.2,29.8s9.5,58,29,57.5s26.5-6.7,29.5-3.3c3,3.3-0.5,4.5-0.5,4.5
		s-10,7.8-29.2,6.2s-39-22.2-41.4-34.8s-2.2-39.6,17.8-52.8S148.1,166.1,154.5,170.2z"
      />
      <path
        fill="#58595B"
        d="M186.5,215.1c0,0,9.9-1.9,11.8,0.6s2.2-16.1-3.2-17.5s-12.2,0.8-12.2,0.8S187,212.2,186.5,215.1z"
      />
      <path
        fill="#58595B"
        d="M76,213.2c0,0,12.9-2,13,0.6s1.2-11.2,1.5-11.9s1.5-3.8,1.5-3.8s-9.6-3.4-12.5-0.8S76,213.2,76,213.2z"
      />
      <path
        fill="#6D6E70"
        d="M77.9,203.6c0,0,3.8-1.2,7-1.2s5.1,0.5,5.1,0.1s2.1-4.7,2-4.3c-0.1,0.3-6.1-1.5-9.9-0.8s-5.2,3-4.8,6.2"
      />
      <path
        fill="#6D6E70"
        d="M184.6,202.9c0,0,11.4-0.4,12.6,0.8s0.9-4.2-3.1-5.1s-7.9-0.8-7.9-0.8l-2.9,0.8L184.6,202.9z"
      />
      <path
        fill="#6D6E70"
        d="M117.5,171.6c0,0,2.8,1.1,0.6,3.8S96.8,186.9,93,206.6s3.4,28.2,2.2,29.6s-3.4-3.4-3.9-3.9
		S85,217.8,90,203.9S101.1,174.9,117.5,171.6z"
      />
      <ellipse fill="#FFFFFF" cx="137" cy="215.9" rx="33.4" ry="33.1" />
      <path
        fill="#E6E7E8"
        d="M126.2,185.4c0,0,27.5,5.4,31.4,27.4s-18.2,39-28,35.6s1.1,2.2,12.4,0.9s25.6-11.9,28-27.4
		s-6.8-31.8-20.5-36.6S126.2,185.4,126.2,185.4z"
      />
      <path
        fill="#30C3EA"
        d="M86.9,299.4l3.6,29.9c0,0,7.4-2.1,7.2,1.2s-4,5.4-4,5.4s7.4,1.5,5.5,3.9s-6.8,3.2-6.8,3.2l1.9,12.2
		c0,0,2.2,5.5,10.8,8s8,2.5,8,2.5s-0.5,6.2,9.1,7.9s16.9,1.6,21.9,0.8s6.6-2,6.6-2.5s0.6-4.2,0.6-4.2s2.4,2.1,13,0.8
		s15.1-3.6,15.1-3.6l1.5-13c0,0-9-0.5-7.5-3.1s4.5-4.2,4.5-4.2s-8.5-1-7.7-3.6c0.8-2.6,2.8-3.6,3.2-3.8s5,0.8,6.9-1.6s6-25.1,6-25.1
		s-15.8,3.1-24.5,2.6s-15.6-0.6-15.6-0.6l-0.4,7c0,0-13.1-0.6-22.4-2.1s-9.6-2.1-9.5-3.1s-0.2-6-0.2-6S93.8,303.8,86.9,299.4z"
      />
      <path
        fill="#00A26F"
        d="M91.6,319.4c0,0-1.8,0.1-1.8,0.5s0.5,2.5,0.5,2.5s3.8,0.9,4.2,0.8s2.9,0.4,3.1-1.5s0.4-3.8,0.4-3.8
		s4.4,0.4,4.5-1.2s-2.8-3.4-5.4-2.8s-4.2,2.1-4.1,2.5S91.6,319.4,91.6,319.4z"
      />
      <path
        fill="#00A26F"
        d="M109,330c-0.9,0.2,0.6,16.8,0.6,16.8s-1.4,3-3.6,4.1s-4.2,4.4-0.1,5.2s3.9-0.4,4.5,0.1s1.2,2.8,1.8,3.1
		s7.6,1.6,10.2,0.2s3.6-2.5,3.2-4.5s-1.8-2.2-1.8-2.2s-8.8-4.8-7.2-9.1s6.9-6.6,6-10.9S117,328.2,109,330z"
      />
      <path
        fill="#00A26F"
        d="M131.6,318.4c0,0-2.9,3.1-2.5,5s1.1,2.6,3.9,2.8s8.9,1.9,8,5.9s-4.4,8.5,0.9,9.6s9.5,0.2,9.8,1.8
		s-0.2,4.9,0.1,4.6s3.2-2.2,3.2-2.2s5.6-2.4,4.1-4.9s-4.2-2.8-3.5-3.8s4.5-2.1,2.4-4.4s-6.4-1.9-6.4-2.6s1.1-12.4,0-12.2
		S139.8,319.6,131.6,318.4z"
      />
      <path
        fill="#00A26F"
        d="M137.6,357.8c0,0-1.8-2.6-0.5-3.4s8.9-2.8,9.4-0.4s1.2,3.1-0.4,4.1s-4.9,1.2-4.9,1.2s1.4,5.4-1.2,5.9
		s-5.8-0.9-5.4-2S137.6,357.8,137.6,357.8z"
      />
      <path
        fill="#00A26F"
        d="M172.8,366.9c0,0-2.6-2.2-1.4-4.5s8-1.2,8.5,0.4s1.4,2.2,0.5,2.1S172.8,366.9,172.8,366.9z"
      />
      <path
        fill="#00A26F"
        d="M163.4,313.2c0,0,0,3.8,4.5,4s6.6,0,6.9,1.8s0.1,4.5,2.2,5.8s3.2,2.2,4.8,0.6s4.4-15.8,4.4-15.8
		L163.4,313.2z"
      />
      <g>
        <path
          fill="#8BB7CC"
          d="M101.8,341.4c1.8-4.9-3.1-6.2-3.1-6.2s1.2-0.8,1.6-2.9s-4.4-4-4.4-4c4.5,4.6-1,7-1,7s3.6,1.1,4.1,3.9
			s-6.2,5-6.2,5l0.2,2.1C93,346.2,100,346.2,101.8,341.4z"
        />
        <path
          fill="#8BB7CC"
          d="M172.2,337.1c0.4,0.1,7,2.9,9.1-3s4.4-23.2,4.4-23.2l-17.2,2.8l-0.5,0.1c0.2,0.8,4.4,36.2-5.1,43.6
			c-4.7,3.7-7.3,4.8-8.6,5.1l1.1-49.2l-7.4-0.5c0,0-1.4,0.9-1.2,1.9s-0.1,5-0.1,5l-12.2-0.1l0.6,0.2c0,0,5.8,34.8-5.2,43
			c-7.8,5.9-13.9,2.5-16.7,0.1c-1-11.3-6.2-51-5.6-50.8c0.6,0.2,6.8,4,6.9,2.5s-0.9-5.2-0.9-5.2l-11.9-3.8c0,0,6.2,38,1.2,43.8
			s-9.1,1.1-9.1,1.1s0.1,6.4,0.5,6.5c0.3,0.1,13.7,7.5,17.8,8.8c0.9,3.5,3.2,8,8.6,9.1c9.2,1.9,23.9,0.9,27.1-1.2s3.8-5.2,3.8-5.2
			l1.8,0.8c0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3,0,0.5,0l0.2,0.1l0-0.1c2.3,0,9.5-0.3,15.4-1.7c6.9-1.6,9.9-1.9,10-3
			s1.4-13.6,1.4-13.6s-8.4,2.2-7.1-1.1s2.9-4.6,2.9-4.6s-7.5-1.5-6.3-4.6C171.5,337.6,171.9,337,172.2,337.1z"
        />
      </g>
      <path
        fill="#C386BA"
        d="M132.8,386.7c0,0-1.8,9.5-1.8,10.3s1.2,10.3,0,10.3c-1.2,0-9-1.8-12.8-0.3s-2.3,7.5,2.7,7
		s15.7-1.8,15.8-1.2c0.2,0.7-0.7-17.2-0.7-17.2l1.3-8.2L132.8,386.7z"
      />
      <path
        fill="#C386BA"
        d="M151.8,388.5c0,0,2,10.3,1.8,14.8c-0.2,4.5-0.3,8.8-0.3,8.8s12.5,4.2,16.7,2c4.2-2.2,6.3-5.7,1.7-7.2
		c-4.7-1.5-5.8-0.5-6.3-0.5s-7,0.8-7,0.8s0-7.5,0.2-8.2c0.2-0.7-1.3-13-1.3-13L151.8,388.5z"
      />
      <path
        fill="#C386BA"
        d="M90.8,157.2c0,0-2-1.5-4-0.5S80,163,80.5,173s5.5,17.8,7.3,16.2c1.8-1.7,8.7-11,8.7-11l7.7-8.5
		C104.2,169.7,92,161.7,90.8,157.2z"
      />
      <path
        fill="#C386BA"
        d="M191.7,171.5c0,0,7.2-2.7,10-6.3c2.8-3.7,8-8,9.3-7.2c1.3,0.8,6.5,13,4.7,20s-7.2,13.7-7.2,13.7
		L191.7,171.5z"
      />
      <path
        fill="#FFFFFF"
        d="M81.7,167.5c0,0,4.2-4.3,7.5-6c3.3-1.7,4.2-1.2,4.2-1.2L99,168c0,0-5,0-10.5,5.2c-5.5,5.2-4.5,10.2-5.7,9.2
		S80.2,168.5,81.7,167.5z"
      />
      <path
        fill="#FFFFFF"
        d="M197,169.2c0,0,7.2,3.3,11.3,8.5c4.2,5.2,4,9.3,4.2,8.8s4.7-9.8,3.2-14.5c-1.5-4.7-2.8-4-2.8-4
		s-6.2-5.7-8.3-5.2S197,169.2,197,169.2z"
      />
      <path
        fill="#FFFFFF"
        d="M131.2,406.8c0,0,3.7-0.3,4.2,1.2s0.7-6.1,0.7-6.1s-4.4-1.5-4.7-0.6S131.2,406.8,131.2,406.8z"
      />
      <path
        fill="#FFFFFF"
        d="M132,395.8c0,0,4.2,0.9,4.3,1.2c0.1,0.2,0.2-5.4,0-5.5s-4.2-1.3-4.9-0.6S132,395.8,132,395.8z"
      />
      <path
        fill="#FFFFFF"
        d="M153.3,407.8c0,0,4.7-2.3,4.8-0.8c0.2,1.6-0.2-5.6-0.2-5.6s-3.9,0.2-4.4,1.3S153.3,407.8,153.3,407.8z"
      />
      <path
        fill="#FFFFFF"
        d="M153.7,396.2c0,0,4.3-1.2,4.2,0.2c-0.1,1.5-1-5.9-1-5.9s-4,0.1-4.1,1C152.8,392.5,153.7,396.2,153.7,396.2z
		"
      />
      <path
        fill="#90B4C7"
        d="M89.7,155.8c0,0-4.5,3-1.7,13.2s5.8,12.8,6,12.3s10.7-12.5,10.7-12.5s-5.7-1.2-8.7-5.5
		C93,159,89.7,155.8,89.7,155.8z"
      />
      <path
        fill="#90B4C7"
        d="M207.8,159.5c0,0,4,5.2-0.2,12.8c-4.2,7.7-8.5,6.2-8.5,6.2s8.5,12.7,11,11.5s4.3-10.8,5.2-14.2
		C216.2,172.5,212.5,154.7,207.8,159.5z"
      />
      <path
        fill="#90B4C7"
        d="M134.3,387.5c0,0-1.3,10.5-1,14.3c0.3,3.8,2.8,8-0.7,8.7s-10.7-3.2-13.5-0.7s-2.8,5,2.7,4.5
		s14.3-0.3,14.8-1.5c0.5-1.2-0.7-9.5-0.2-14.3s1-10.2,1-10.2l-3.8-0.8"
      />
      <path
        fill="#90B4C7"
        d="M153.8,388.2c0,0,2.2,11.5,1.7,14.5s-0.3,8.3,3.7,8.2c4-0.2,8-2.2,8.8,0.3s5.2,1.2,4.8-1.7
		c-0.3-2.8-2.3-4.8-6-3.8c-3.7,1-8.3,2-8.7,1.3s0.1-11,0.2-11.8c0.2-0.8-1.3-7.7-1.8-7.5S153.8,388.2,153.8,388.2z"
      />
      <path
        fill="#231F20"
        d="M137.1,250.1c-18.7,0-34-15.2-34-34c0-18.7,15.2-34,34-34c18.7,0,34,15.2,34,34
		C171.1,234.8,155.8,250.1,137.1,250.1z M137.1,183.4c-18,0-32.7,14.7-32.7,32.7c0,18,14.7,32.7,32.7,32.7c18,0,32.7-14.7,32.7-32.7
		C169.8,198.1,155.1,183.4,137.1,183.4z"
      />
      <path
        fill="#231F20"
        d="M137.1,266.1c-27.6,0-50-22.4-50-50c0-27.6,22.4-50,50-50c27.6,0,50,22.4,50,50
		C187.1,243.7,164.7,266.1,137.1,266.1z M137.1,168.5c-26.3,0-47.6,21.4-47.6,47.6s21.4,47.6,47.6,47.6c26.3,0,47.6-21.4,47.6-47.6
		S163.3,168.5,137.1,168.5z"
      />
      <path
        fill="#231F20"
        d="M129.8,237.8c0,2.2,1.8,3.9,3.9,3.9c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9
		C131.6,233.8,129.8,235.6,129.8,237.8z"
      />
      <path
        fill="#FFFFFF"
        d="M134.7,236.3c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2C135.6,234.3,134.7,235.2,134.7,236.3z"
      />
      <path
        fill="#231F20"
        d="M86.7,241.5c-2,0-4.3-0.2-6.6-0.9c-1.2-0.3-2.2-1.4-3-3c-3.9-8.5-2.2-34-0.2-38.5c1.5-3.4,6.4-4.1,14.5-2.2
		c0.2,0,0.3,0.1,0.4,0.1c0.6,0.1,1.1,0.6,1.1,1.2c0,0.7-0.5,1.2-1.2,1.2c-0.2,0-0.3,0-0.8-0.2c-8.9-2.2-11.2-0.4-11.7,0.8
		c-1.7,3.8-3.4,28.8,0.2,36.5c0.5,1,1,1.6,1.5,1.7c6.1,1.8,12.3,0,12.3,0c0.6-0.2,1.3,0.2,1.5,0.8c0.2,0.6-0.2,1.3-0.8,1.5
		C93.5,240.7,90.6,241.5,86.7,241.5z"
      />
      <path
        fill="#231F20"
        d="M187.8,241.5c-3.9,0-6.9-0.8-7.1-0.9c-0.6-0.2-1-0.8-0.8-1.5c0.2-0.6,0.8-1,1.5-0.8c0.1,0,6.3,1.8,12.3,0
		c0.5-0.1,1-0.8,1.5-1.7c3.6-7.7,1.9-32.7,0.2-36.5c-0.5-1.2-2.8-3-11.7-0.8c-0.5,0.1-0.6,0.2-0.8,0.2c-0.7,0-1.2-0.5-1.2-1.2
		c0-0.6,0.5-1.1,1.1-1.2c0.1,0,0.2,0,0.4-0.1c8.1-2,13-1.2,14.5,2.2c2,4.5,3.8,30-0.2,38.5c-0.8,1.7-1.8,2.7-3,3
		C192.1,241.3,189.8,241.5,187.8,241.5z"
      />
      <path
        fill="#231F20"
        d="M148.2,299.8c-14.6,0-27.7-9.5-31.5-18.3c-3.7-8.6-0.8-14.9,0.1-16.6c1-1.8,2.3-3,3.5-3.1
		c2.7-0.3,5.4,1.6,5.9,1.9c0.1,0,0.1,0.1,0.2,0.1c4.3,2.4,10.1,2.9,14.2,2.9c1.5,0,3.1-0.1,4.8-0.2c8.7-0.7,14.7-4.7,19.6-7.9
		c2.7-1.8,4.8-3.2,6.8-3.6c3-0.7,6,0.3,7.9,2.5c1.7,1.9,3.5,5.8,2.4,13.4c-2,14-12.9,25.1-27.8,28.2
		C152.4,299.6,150.3,299.8,148.2,299.8z M120.9,263.1c-0.2,0-0.3,0-0.5,0c-0.6,0.1-1.6,0.9-2.5,2.5c-1.8,3.2-2.8,9-0.1,15.4
		c4.5,10.5,19.3,17.5,30.3,17.5c2,0,4-0.2,5.9-0.6c14.4-3,24.9-13.6,26.7-27.1c1-7.1-0.6-10.7-2.1-12.4c-1.6-1.9-3.6-2.2-5-2.2
		c-0.6,0-1.1,0.1-1.7,0.2c-1.8,0.4-3.9,1.8-6.4,3.5c-4.7,3.1-11.1,7.4-20.2,8.1c-1.7,0.1-3.3,0.2-4.9,0.2c-4.2,0-10.2-0.5-14.8-3.1
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1C125.4,264.8,123.1,263.1,120.9,263.1z"
      />
      <path
        fill="#231F20"
        d="M152.5,303.9c-1,0-1.7-0.1-1.8-0.1c-0.4,0-0.6-0.3-0.6-0.7c0-0.4,0.3-0.6,0.7-0.6c0.1,0,6.5,0.5,9.5-1.5
		c0.3-0.2,0.7-0.1,0.9,0.2c0.2,0.3,0.1,0.7-0.2,0.9C158.6,303.6,154.7,303.9,152.5,303.9z"
      />
      <path
        fill="#231F20"
        d="M134.3,296.7c-0.1,0-0.2,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0.2-0.4,3.8-10,15.5-14.2
		c11.8-4.3,22.6,1.4,25.1,5.6c0.2,0.3,0.1,0.7-0.2,0.9c-0.3,0.2-0.7,0.1-0.9-0.2c-2.3-3.8-12.6-9-23.6-5.1
		c-11.1,4-14.7,13.4-14.7,13.4C134.8,296.5,134.5,296.7,134.3,296.7z"
      />
      <path
        fill="#231F20"
        d="M134.8,278.8c-1.4,0-2.9-0.3-4.5-0.8c-3.5-1.2-5.9-3.2-7.1-5.8c-1.9-4.3,0-8.8,0-9c0.1-0.3,0.5-0.5,0.9-0.3
		c0.3,0.1,0.5,0.5,0.3,0.9c0,0-1.7,4.2,0,7.9c1,2.3,3.2,4,6.3,5.1c3.5,1.2,6.7,0.9,9.3-0.9c3.5-2.4,5.1-7,5.1-8.4
		c0-0.4,0.3-0.6,0.6-0.6c0,0,0,0,0,0c0.4,0,0.6,0.3,0.6,0.7c0,1.4-1.5,6.5-5.7,9.4C138.9,278.2,136.9,278.8,134.8,278.8z"
      />
      <path
        fill="#231F20"
        d="M163.2,272.1c-7.7,0-10.2-6.1-10.3-6.2c-0.1-0.3,0-0.7,0.4-0.8c0.3-0.1,0.7,0,0.8,0.4
		c0.1,0.2,2.4,5.7,9.8,5.3c3.6-0.2,6.8-2.4,8.3-5.7c1.4-3.1,1.1-6.5-0.8-9c-0.2-0.3-0.2-0.7,0.1-0.9c0.3-0.2,0.7-0.2,0.9,0.1
		c2.2,2.9,2.6,6.8,0.9,10.3c-1.7,3.8-5.4,6.3-9.4,6.5C163.7,272.1,163.4,272.1,163.2,272.1z"
      />
      <path
        fill="#231F20"
        d="M103,306.5c-0.6,0-1.2-0.5-1.2-1.2c-0.3-9.8-1.9-17.5-4.9-24.1c-3.3-7.2-7.4-13.7-11.5-20
		c-9.9-15.5-19.3-30.1-10.5-49.3c0.3-0.6,1-0.9,1.6-0.6c0.6,0.3,0.9,1,0.6,1.6c-8.3,18,0.3,31.4,10.3,47c4.1,6.4,8.3,12.9,11.6,20.3
		c3.2,6.9,4.8,14.9,5.1,25C104.2,305.9,103.7,306.4,103,306.5C103,306.5,103,306.5,103,306.5z"
      />
      <path
        fill="#231F20"
        d="M146.3,389.2c-5.4,0-11.2-0.6-16.7-2.1c-12.5-3.4-21.1-10.7-25.5-21.7c-0.2-0.5-0.4-1.1-0.6-1.7
		c-0.2-0.6,0.1-1.3,0.7-1.5c0.6-0.2,1.3,0.1,1.5,0.7c0.2,0.5,0.4,1,0.6,1.6c2.8,7,15.2,29.3,58.5,20.3c21.2-4.4,28.4-28.8,26.6-44
		c-0.1-0.7,0.4-1.3,1.1-1.3c0.6-0.1,1.3,0.4,1.3,1.1c1.9,16-5.9,41.9-28.5,46.6C160,388.3,153.4,389.2,146.3,389.2z"
      />
      <path
        fill="#231F20"
        d="M191.6,334.5c-0.6,0-1.1-0.4-1.2-1.1c-0.5-3.8-1.1-8.9-1.4-14.6c0-0.7,0.5-1.2,1.1-1.3
		c0.6,0,1.2,0.5,1.3,1.1c0.3,5.6,0.9,10.6,1.3,14.4c0.1,0.7-0.4,1.3-1,1.3C191.7,334.5,191.6,334.5,191.6,334.5z"
      />
      <path
        fill="#231F20"
        d="M190,312.1C190,312.1,190,312.1,190,312.1c-0.7,0-1.2-0.5-1.2-1.2c0-9.1,0.9-17.4,2.8-25.5
		c2.4-10.1,8.3-18.4,14.1-26.5c6.8-9.6,13.3-18.7,14.4-30.5c2.2-23.1-19.4-49.8-40.3-64.9c-21.2-15.3-41-16.1-62.2-2.8
		c-15.8,9.9-28.8,28.7-33.7,36.3c-0.4,0.6-1.1,0.7-1.7,0.4c-0.6-0.4-0.7-1.1-0.4-1.7c5-7.7,18.3-26.9,34.4-37
		c21.8-13.8,43-12.8,64.8,2.9c12,8.6,22.8,19.7,30.2,31.1c8.3,12.7,12.1,25.1,11.1,36c-1.2,12.4-8.1,22.2-14.8,31.6
		c-5.6,7.9-11.4,16.1-13.7,25.7c-1.9,7.9-2.8,16.1-2.8,25C191.2,311.6,190.7,312.1,190,312.1z"
      />
      <path
        fill="#231F20"
        d="M209.4,191.7c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.2,0-1.7c5.8-5.7,6.3-13.4,5.7-18.9
		c-0.7-6.4-3-11-3.8-11.5c-1.5-0.7-2.1-0.2-6.2,5l-0.4,0.6c-4.5,5.6-11.5,7.4-11.8,7.4c-0.6,0.2-1.3-0.2-1.4-0.9
		c-0.2-0.6,0.2-1.3,0.9-1.4c0.1,0,6.5-1.6,10.5-6.6l0.4-0.6c3.9-5,5.7-7.3,9.1-5.7c1.8,0.9,4,5.8,4.9,11.3c0.9,5.4,1.4,15.7-6.1,23
		C210,191.6,209.7,191.7,209.4,191.7z"
      />
      <path
        fill="#231F20"
        d="M86.9,191.5c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-5.8-3.7-6.4-17.6c-0.5-10.1,3.4-17.4,7.2-19.1
		c1.5-0.6,2.8-0.4,3.8,0.5c0.8,0.8,1.8,2.1,2.9,3.7c2.7,3.6,7.3,9.7,10.3,9.7c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2
		c-4.3,0-8.9-6.2-12.3-10.7c-1.1-1.5-2.1-2.8-2.7-3.4c-0.1-0.1-0.4-0.4-1.2,0c-2.6,1.1-6.2,7.3-5.8,16.8c0.6,12.4,5.3,15.6,5.3,15.7
		c0.5,0.4,0.7,1.1,0.4,1.7C87.7,191.3,87.3,191.5,86.9,191.5z"
      />
      <path
        fill="#231F20"
        d="M83,183.7c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0-0.1,4.4-14.3,17-16.2c0.4-0.1,0.7,0.2,0.7,0.5
		c0.1,0.4-0.2,0.7-0.5,0.7c-11.8,1.8-15.9,15.2-16,15.3C83.5,183.5,83.3,183.7,83,183.7z"
      />
      <path
        fill="#231F20"
        d="M81,169.7c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.6-0.2-0.9c0.2-0.4,6-8.8,13.9-8.8c0.4,0,0.7,0.3,0.7,0.6
		c0,0.4-0.3,0.6-0.7,0.6c-7.2,0-12.8,8.1-12.8,8.2C81.4,169.6,81.2,169.7,81,169.7z"
      />
      <path
        fill="#231F20"
        d="M212.3,187.2c-0.3,0-0.6-0.2-0.6-0.5c-2.4-11.9-14.5-16.6-14.6-16.6c-0.3-0.1-0.5-0.5-0.4-0.8
		c0.1-0.3,0.5-0.5,0.8-0.4c0.1,0,12.9,5,15.4,17.6c0.1,0.4-0.2,0.7-0.5,0.8C212.3,187.2,212.3,187.2,212.3,187.2z"
      />
      <path
        fill="#231F20"
        d="M215.4,172.5c-0.3,0-0.5-0.2-0.6-0.4c-2.2-6.6-11-8.8-11-8.8c-0.3-0.1-0.6-0.4-0.5-0.8
		c0.1-0.3,0.4-0.6,0.8-0.5c0.4,0.1,9.5,2.4,12,9.7c0.1,0.3-0.1,0.7-0.4,0.8C215.5,172.5,215.4,172.5,215.4,172.5z"
      />
      <path
        fill="#231F20"
        d="M190,335.3c-1,0-2-0.2-2.5-0.4c-1.1-0.4-1.4-1.6-1.8-2.9c-0.5-1.7-1.1-3.9-3.2-4.9c-0.6-0.3-0.8-1-0.5-1.6
		c0.3-0.6,1-0.8,1.6-0.5c3,1.5,3.8,4.4,4.4,6.3c0.1,0.5,0.3,1.1,0.5,1.4c0.7,0.2,2.6,0.5,4-0.4c1.3-0.8,2-2.4,2.1-4.8
		c0.4-6.7-6.2-8.6-6.5-8.6c-0.6-0.2-1-0.8-0.8-1.5c0.2-0.6,0.8-1,1.5-0.8c3.1,0.8,8.7,4.2,8.3,11.1c-0.2,3.2-1.3,5.4-3.2,6.7
		C192.5,335,191.2,335.3,190,335.3z"
      />
      <path
        fill="#231F20"
        d="M186.8,343.1c-0.6,0-1.1-0.4-1.2-1.1c-0.1-0.7,0.4-1.3,1.1-1.3c4.6-0.5,8.9-3.1,11.3-6.8
		c2.1-3.2,2.6-7,1.5-10.6c-2.6-8.3-11.2-11.3-11.2-11.4c-0.6-0.2-1-0.9-0.7-1.5c0.2-0.6,0.9-1,1.5-0.7c0.4,0.1,9.7,3.4,12.8,12.9
		c1.4,4.3,0.8,8.8-1.7,12.6c-2.8,4.3-7.7,7.3-13,7.9C186.8,343.1,186.8,343.1,186.8,343.1z"
      />
      <path
        fill="#231F20"
        d="M175.6,345.2c-2.7,0-5.1-1.2-6.1-3.2c-0.8-1.4-0.7-3.1,0.3-4.3c1.5-2,3.4-1.8,5.3-1.7
		c1,0.1,2.1,0.1,3.3-0.1c2.8-0.5,3.5-1.9,3.5-2c0.3-0.6,1-0.9,1.6-0.6c0.6,0.3,0.9,0.9,0.6,1.5c-0.1,0.3-1.2,2.6-5.3,3.4
		c-1.5,0.3-2.8,0.2-3.9,0.1c-1.8-0.1-2.5-0.1-3.2,0.8c-0.5,0.7-0.3,1.3-0.1,1.8c0.6,1,2.1,2,4.4,1.9c3.2-0.2,4.2-0.6,4.4-0.7
		c0.4-0.4,1.1-0.4,1.5,0c0.5,0.4,0.6,1.2,0.2,1.7c-0.6,0.8-2.6,1.3-6,1.5C175.9,345.2,175.7,345.2,175.6,345.2z"
      />
      <path
        fill="#231F20"
        d="M176.8,353c-0.6,0-1.1-0.1-1.5-0.2c-1.4-0.3-2.3-1.1-2.6-2.2c-1.1-3.4,1.5-4.6,3-5.4
		c1.2-0.6,1.6-0.8,1.5-1.2c0-0.7,0.5-1.2,1.1-1.3c0.7,0,1.2,0.5,1.3,1.1c0.1,2.1-1.6,2.9-2.9,3.5c-1.7,0.8-2.1,1.2-1.7,2.5
		c0.1,0.2,0.4,0.4,0.9,0.5c1.6,0.4,4.2-0.2,6.4-2.5c3.4-3.4,2.6-6.8,2.6-7c-0.1-0.6,0.3-1.3,0.9-1.4c0.7-0.1,1.3,0.2,1.4,0.9
		c0,0.2,1.1,4.9-3.2,9.2C181.5,352.1,178.8,353,176.8,353z"
      />
      <path
        fill="#231F20"
        d="M92.4,344.3c-2.7,0-5.3-0.8-7.3-2.2c-2.2-1.6-3.4-4-3.5-6.9c-0.3-7.4,6.4-13,6.7-13.2
		c0.5-0.4,1.3-0.3,1.7,0.2c0.4,0.5,0.3,1.3-0.2,1.7c-0.1,0-6,5.1-5.8,11.3c0.1,2.2,0.9,3.9,2.6,5.1c2.2,1.6,5.8,2.2,8.8,1.3
		c2.2-0.6,3-1.6,2.9-2.3c0-0.7-0.8-1.3-1.8-1.4c-3.2-0.5-5,0.1-5,0.1c-0.6,0.2-1.3-0.1-1.5-0.7c-0.2-0.6,0.1-1.3,0.7-1.5
		c0.1,0,2.3-0.9,6.2-0.2c2.2,0.4,3.7,1.8,3.8,3.7c0.1,1.7-1.1,3.8-4.7,4.8C94.8,344.1,93.6,344.3,92.4,344.3z"
      />
      <path
        fill="#231F20"
        d="M89.9,337.8c-0.5,0-1-0.4-1.2-0.9c-0.2-0.6,0.2-1.3,0.9-1.5c2.3-0.6,6.1-2.1,6.6-3.9c0.3-0.9,0.3-1.7-0.1-2
		c-0.4-0.4-1.5-0.6-3.3,0c-3.3,1.2-4.8,3.8-4.8,3.9c-0.3,0.6-1,0.8-1.6,0.5c-0.6-0.3-0.8-1-0.5-1.6c0.1-0.1,1.9-3.5,6.2-5
		c2.5-0.9,4.5-0.7,5.7,0.5c0.6,0.6,1.5,2,0.7,4.5c-1.2,3.7-7.6,5.4-8.4,5.6C90.1,337.8,90,337.8,89.9,337.8z"
      />
      <path
        fill="#231F20"
        d="M99.3,304.9c-0.2,0-0.5-0.1-0.7-0.2c-0.5-0.4-0.6-1.1-0.2-1.7c0,0,2.7-3.7,2.7-7.6c0-0.7,0.5-1.2,1.2-1.2
		s1.2,0.5,1.2,1.2c0,4.8-3,8.9-3.2,9C100,304.7,99.6,304.9,99.3,304.9z"
      />
      <path
        fill="#231F20"
        d="M134.3,376.5c-13,0-20.1-2.7-21.9-8.4c-2.1-6.7-6.7-54.2-6.9-56.3c0-0.5,0.2-0.9,0.6-1.2
		c0.4-0.2,0.9-0.2,1.3,0.1c0,0,4.8,3.3,19.7,5.5c4.4,0.7,8.7,1,12.8,1c7.4,0,11.8-1.1,11.8-1.1c0.4-0.1,0.8,0,1,0.2
		c0.3,0.2,0.5,0.6,0.5,1l-1,52.8c0,0.1,0,0.2-0.1,0.4c-0.7,2.1-3.6,5.7-15.4,5.9C136,376.5,135.1,376.5,134.3,376.5z M108,313.9
		c1,9.7,4.8,47.9,6.6,53.5c1.4,4.4,8,6.7,19.7,6.7c0.8,0,1.6,0,2.4,0c10.4-0.3,12.6-3.1,13.1-4.2l1-51c-1.9,0.4-5.7,0.9-10.9,0.9
		c-4.3,0-8.7-0.3-13.2-1C116.4,317.1,110.8,315.1,108,313.9z"
      />
      <path
        fill="#231F20"
        d="M114.3,315.4c-0.6,0-1.1-0.5-1.2-1.1l-0.3-3.8l-5.6,2.4c-0.6,0.3-1.3,0-1.6-0.6c-0.3-0.6,0-1.3,0.6-1.6
		l7.1-3c0.4-0.1,0.8-0.1,1.1,0.1c0.3,0.2,0.5,0.5,0.6,0.9l0.5,5.4c0.1,0.7-0.4,1.2-1.1,1.3C114.3,315.4,114.3,315.4,114.3,315.4z"
      />
      <path
        fill="#231F20"
        d="M145.8,319.2C145.8,319.2,145.8,319.2,145.8,319.2c-0.7,0-1.2-0.6-1.2-1.3l0.3-6.5c0-0.5,0.3-0.9,0.8-1.1
		c0.4-0.2,0.9-0.1,1.3,0.3l5.9,5.9c0.5,0.5,0.5,1.2,0,1.7c-0.5,0.5-1.2,0.5-1.7,0l-3.9-3.9l-0.2,3.8
		C147,318.7,146.5,319.2,145.8,319.2z"
      />
      <path
        fill="#231F20"
        d="M158.7,370.5c-2.4,0-4.8-0.2-7.2-0.7c-0.7-0.1-1.1-0.8-1-1.4c0.1-0.7,0.8-1.1,1.4-1
		c11.6,2.2,23.9-2.4,27-3.6c0-2-0.1-7.4,0-12.5c0-0.7,0.5-1.2,1.2-1.2c0,0,0,0,0,0c0.7,0,1.2,0.6,1.2,1.2c-0.1,6.3,0,13,0,13.3
		c0,0.5-0.3,0.9-0.7,1.1C180.2,366,170.1,370.5,158.7,370.5z"
      />
      <path
        fill="#231F20"
        d="M181.2,336.7c0,0-0.1,0-0.1,0c-0.7-0.1-1.1-0.7-1-1.3c1.3-10.5,3.5-19.7,4.4-23.5
		c-3.2,0.6-10.2,1.8-19.2,2.1c-12.4,0.4-19.1-1.2-19.4-1.3c-0.6-0.2-1-0.8-0.9-1.5c0.2-0.6,0.8-1,1.5-0.9c0.1,0,6.7,1.6,18.7,1.2
		c12.1-0.4,20.6-2.4,20.7-2.5c0.4-0.1,0.9,0,1.1,0.3c0.3,0.3,0.4,0.7,0.3,1.2c0,0.1-3.2,11.4-4.9,25.1
		C182.3,336.3,181.8,336.7,181.2,336.7z"
      />
      <path
        fill="#231F20"
        d="M112.5,367.2c-0.1,0-0.2,0-0.3,0c-10.2-2.4-18.1-9.2-18.4-9.5c-0.2-0.2-0.3-0.4-0.4-0.7
		c0-0.1-1-5.3-2.2-13.5c-0.1-0.7,0.4-1.3,1-1.4c0.6-0.1,1.3,0.4,1.4,1c1,6.8,1.9,11.6,2.1,13c1.5,1.3,8.6,6.7,17.1,8.7
		c0.6,0.2,1,0.8,0.9,1.4C113.5,366.8,113,367.2,112.5,367.2z"
      />
      <path
        fill="#231F20"
        d="M90.5,330.2c-0.6,0-1.1-0.4-1.2-1.1c-1.7-14-4.4-31.1-4.4-31.2c-0.1-0.5,0.2-1,0.6-1.2
		c0.4-0.2,1-0.2,1.3,0.1c7.6,6,27,10.7,27.2,10.8c0.6,0.2,1,0.8,0.9,1.4c-0.2,0.6-0.8,1-1.4,0.9c-0.7-0.2-16.8-4.1-25.7-9.6
		c0.8,5.4,2.7,17.9,4,28.5c0.1,0.7-0.4,1.3-1,1.3C90.6,330.2,90.6,330.2,90.5,330.2z"
      />
      <path
        fill="#231F20"
        d="M122.9,415.7c-2.9,0-5.4-0.4-6.6-1.8c-1.7-2-2.2-4.3-1.2-6.2c1-2.1,3.5-3.2,6.7-3c3.6,0.2,6.5,1,8.2,1.7
		c-0.3-4.3-0.6-14.2,1.3-19.7c0.2-0.6,0.9-1,1.5-0.7c0.6,0.2,1,0.9,0.7,1.5c-2.3,6.6-1.1,20.6-1.1,20.8c0,0.4-0.2,0.9-0.6,1.1
		c-0.4,0.2-0.9,0.2-1.3,0c0,0-3.6-2.1-9.1-2.3c-2.3-0.1-3.9,0.5-4.4,1.7c-0.5,1-0.1,2.3,0.9,3.6c1.4,1.7,9.8,0.9,17.3-0.8
		c-0.3-2.1-0.7-7-0.5-11.9c0.4-7.1,1.1-11.7,1.1-11.9c0.1-0.7,0.7-1.1,1.4-1c0.7,0.1,1.1,0.7,1,1.4c0,0-0.7,4.7-1.1,11.6
		c-0.3,6.2,0.6,12.4,0.6,12.5c0.1,0.6-0.3,1.2-0.9,1.3C135.2,414.1,128.3,415.7,122.9,415.7z"
      />
      <path
        fill="#231F20"
        d="M136.4,409c-0.2,0-0.4-0.1-0.5-0.2c-1.7-2-4.3-1.6-4.4-1.5c-0.4,0.1-0.7-0.2-0.8-0.5
		c-0.1-0.4,0.2-0.7,0.5-0.8c0.1,0,3.4-0.7,5.6,2c0.2,0.3,0.2,0.7-0.1,0.9C136.7,409,136.5,409,136.4,409z"
      />
      <path
        fill="#231F20"
        d="M135.8,403.5c-0.2,0-0.4-0.1-0.5-0.3c-1.2-1.7-3.9-1.1-3.9-1.1c-0.4,0.1-0.7-0.1-0.8-0.5
		c-0.1-0.3,0.1-0.7,0.5-0.8c0.1,0,3.6-0.8,5.3,1.6c0.2,0.3,0.1,0.7-0.2,0.9C136,403.5,135.9,403.5,135.8,403.5z"
      />
      <path
        fill="#231F20"
        d="M136.5,397.9c-0.2,0-0.5-0.1-0.6-0.4c-0.5-0.9-3.1-1.3-4.8-1.3c-0.3,0-0.7-0.3-0.7-0.6
		c0-0.4,0.3-0.7,0.6-0.7c0.5,0,4.9-0.1,6,2c0.2,0.3,0,0.7-0.3,0.9C136.7,397.9,136.6,397.9,136.5,397.9z"
      />
      <path
        fill="#231F20"
        d="M137,393.2c-0.2,0-0.5-0.1-0.6-0.3c-1-1.8-4.4-1.4-4.5-1.4c-0.3,0-0.7-0.2-0.7-0.6c0-0.4,0.2-0.7,0.6-0.7
		c0.2,0,4.3-0.5,5.8,2.1c0.2,0.3,0.1,0.7-0.3,0.9C137.2,393.1,137.1,393.2,137,393.2z"
      />
      <path
        fill="#231F20"
        d="M166.2,415.7c-5.4,0-12.2-1.5-14.1-2c-0.6-0.1-1-0.7-0.9-1.3c0-0.1,0.9-6.3,0.6-12.5
		c-0.4-7-1.1-11.6-1.1-11.6c-0.1-0.7,0.3-1.3,1-1.4c0.7-0.1,1.3,0.3,1.4,1c0,0.2,0.8,4.8,1.1,11.9c0.3,4.9-0.2,9.8-0.5,11.9
		c7.4,1.7,15.8,2.4,17.3,0.8c1.1-1.2,1.4-2.6,0.9-3.6c-0.6-1.2-2.2-1.8-4.4-1.7c-5.5,0.2-9,2.3-9.1,2.3c-0.4,0.2-0.9,0.2-1.2,0
		c-0.4-0.2-0.6-0.7-0.6-1.1c0-0.1,1.1-13.6-1.2-20.1c-0.2-0.6,0.1-1.3,0.7-1.5c0.6-0.2,1.3,0.1,1.5,0.7c1.9,5.5,1.7,14.9,1.4,19.1
		c1.8-0.7,4.6-1.6,8.2-1.7c3.2-0.1,5.7,1,6.7,3c0.9,1.9,0.5,4.2-1.2,6.2C171.7,415.2,169.1,415.7,166.2,415.7z"
      />
      <path
        fill="#231F20"
        d="M152.7,409c-0.1,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.3-0.6-0.1-0.9c2.2-2.6,5.5-2,5.6-2c0.4,0.1,0.6,0.4,0.5,0.8
		c-0.1,0.4-0.4,0.6-0.8,0.5c-0.1,0-2.7-0.5-4.4,1.5C153.1,409,152.9,409,152.7,409z"
      />
      <path
        fill="#231F20"
        d="M153.4,403.5c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.6-0.2-0.9c1.7-2.5,5.2-1.7,5.3-1.6
		c0.3,0.1,0.6,0.4,0.5,0.8c-0.1,0.3-0.4,0.6-0.8,0.5c0,0-2.7-0.6-3.9,1.1C153.8,403.4,153.6,403.5,153.4,403.5z"
      />
      <path
        fill="#231F20"
        d="M152.6,397.9c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.6-0.3-0.9c1-2.1,5.5-2,6-2c0.4,0,0.6,0.3,0.6,0.7
		c0,0.4-0.3,0.6-0.7,0.6c-1.7,0-4.3,0.3-4.8,1.3C153.1,397.8,152.9,397.9,152.6,397.9z"
      />
      <path
        fill="#231F20"
        d="M152.1,393.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.4-0.6-0.3-0.9c1.4-2.6,5.6-2.1,5.8-2.1
		c0.4,0,0.6,0.4,0.6,0.7c0,0.4-0.4,0.6-0.7,0.6c0,0-3.5-0.4-4.5,1.4C152.6,393,152.3,393.2,152.1,393.2z"
      />
      <path
        fill="#231F20"
        d="M117.6,361c-2.8,0-5.2-0.4-5.4-0.5c-0.4-0.1-0.6-0.4-0.5-0.8c0.1-0.4,0.4-0.6,0.8-0.5
		c3.2,0.6,11.7,1.3,12.8-2.7c0.7-2.4-0.3-2.9-2.4-4c-1.7-0.9-4.1-2-6-4.9c-2.3-3.4,0.4-6.5,2.7-9.2c2.5-2.9,3.9-4.8,1.9-6.7
		c-4.6-4.5-12.6-0.7-12.6-0.6c-0.3,0.2-0.7,0-0.9-0.3c-0.2-0.3,0-0.7,0.3-0.9c0.4-0.2,8.8-4.3,14.1,0.9c2.9,2.9,0.4,5.8-1.8,8.5
		c-2.2,2.6-4.3,5-2.6,7.6c1.7,2.5,3.8,3.6,5.5,4.4c2.1,1,4.1,2,3.1,5.5C125.5,360.3,121.2,361,117.6,361z"
      />
      <path
        fill="#231F20"
        d="M111.3,358c-0.2,0-0.4-0.1-0.5-0.3c-0.4-0.5-1.1-0.5-2.2-0.4c-1.5,0.1-3.4,0.3-5-1.4
		c-0.7-0.8-1-1.5-0.9-2.2c0.1-1.3,1.4-2.2,2.7-3.1c0.8-0.6,1.6-1.1,2.2-1.8c1.9-2.1,1.5-5.7,1.5-5.7c0-0.4,0.2-0.7,0.6-0.7
		c0.4,0,0.7,0.2,0.7,0.6c0,0.2,0.5,4.2-1.8,6.8c-0.7,0.8-1.6,1.4-2.4,2c-1.1,0.8-2.1,1.5-2.2,2.2c0,0.3,0.2,0.7,0.6,1.2
		c1.2,1.3,2.5,1.2,3.9,1c1.3-0.1,2.6-0.2,3.4,0.9c0.2,0.3,0.1,0.7-0.1,0.9C111.5,358,111.4,358,111.3,358z"
      />
      <path
        fill="#231F20"
        d="M90.3,323.8c-0.1,0-0.2,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0.5-1.2,2-1,3.6-0.8c1.1,0.1,2.3,0.3,3,0
		c1-0.3,1-1,0.9-2.7c0-0.4-0.1-0.8-0.1-1.2c0-0.5,0.1-0.8,0.4-1.1c0.5-0.4,1.2-0.4,1.9-0.3c0.8,0.1,1.7,0.1,2.5-0.4
		c0.4-0.3,0.3-0.5,0.3-0.6c-0.3-0.8-2.1-1.9-4.3-1.7c-3.7,0.3-4.5,3.2-4.7,4.1c-0.1,0.7-0.5,1.2-1,1.6c-1.1,0.7-2.5,0.5-2.6,0.4
		c-0.4-0.1-0.6-0.4-0.5-0.8c0.1-0.4,0.4-0.6,0.8-0.5c0.3,0.1,1.1,0.1,1.7-0.2c0.2-0.2,0.4-0.4,0.5-0.7c0.4-2.3,2.2-4.9,5.8-5.2
		c2.5-0.2,5.1,1,5.6,2.6c0.3,0.8-0.1,1.6-0.9,2.1c-1.1,0.7-2.4,0.6-3.2,0.5c-0.3,0-0.8-0.1-0.9,0c0,0,0,0.1,0,0.2
		c0,0.4,0,0.7,0.1,1.1c0.1,1.4,0.3,3.4-1.8,4c-1,0.3-2.4,0.2-3.6,0c-0.7-0.1-2.1-0.2-2.3,0C90.8,323.6,90.5,323.8,90.3,323.8z"
      />
      <path
        fill="#231F20"
        d="M151.1,345.3c-0.3,0-0.6-0.3-0.6-0.6c-0.1-1.9-1.2-2-4-1.9c-2,0.1-4.4,0.1-6-1.5c-2.2-2.2-1.7-3.6-1-5.4
		c0.4-1.2,1-2.7,1-5c0-2.6-2-2.9-5.4-3.1c-2.6-0.2-5.2-0.4-6.3-2.5c-0.6-1.2-0.8-2.4-0.4-3.5c0.8-2.4,3.6-3.7,3.7-3.7
		c0.3-0.2,0.7,0,0.9,0.3c0.2,0.3,0,0.7-0.3,0.9c0,0-2.4,1.1-3,3c-0.3,0.8-0.2,1.6,0.3,2.5c0.8,1.5,2.9,1.6,5.3,1.8
		c2.9,0.2,6.6,0.5,6.6,4.4c0,2.6-0.6,4.3-1,5.5c-0.6,1.7-0.9,2.4,0.7,4.1c1.2,1.3,3.1,1.2,5,1.1c2.4-0.1,5-0.2,5.3,3.1
		C151.8,344.9,151.5,345.3,151.1,345.3C151.2,345.3,151.1,345.3,151.1,345.3z"
      />
      <path
        fill="#231F20"
        d="M151.5,350.8C151.5,350.8,151.5,350.8,151.5,350.8c-0.4,0-0.7-0.3-0.7-0.7c0.2-3.4,2.6-4.5,4.6-5.4
		c1.4-0.6,2.5-1.1,2.9-2.3c0.6-1.6,0-1.9-1.2-2.5c-0.6-0.3-1.3-0.6-1.8-1.2c-1-1.2,0-2.2,0.7-2.9c0.7-0.7,1.5-1.4,1.5-2.6
		c0-0.3-0.1-0.6-0.3-0.8c-1.1-1.2-4.4-1.6-5.5-1.6c-0.4,0-0.6-0.3-0.6-0.7c0-0.4,0.3-0.6,0.7-0.6c0.5,0,4.8,0.2,6.5,2
		c0.5,0.5,0.7,1.1,0.7,1.8c-0.1,1.7-1.1,2.7-1.9,3.5c-0.8,0.8-0.8,0.9-0.6,1.1c0.3,0.4,0.8,0.6,1.4,0.9c1.2,0.6,2.8,1.4,1.9,4.1
		c-0.6,1.6-2.1,2.3-3.6,3c-1.9,0.9-3.7,1.7-3.8,4.3C152.1,350.5,151.8,350.8,151.5,350.8z"
      />
      <path
        fill="#231F20"
        d="M138.5,366.1L138.5,366.1c-1.3,0-2.8-0.4-3.8-1c-0.6-0.4-1-0.9-1.1-1.4c-0.2-1.2,0.8-2.3,1.9-3.5
		c0.3-0.3,0.6-0.7,0.9-1c0.4-0.5,0.5-0.7,0.5-0.7c0,0-0.1-0.1-0.2-0.2c-0.3-0.4-0.8-1-0.8-2.4c0-0.5,0.2-1,0.6-1.4
		c1.8-1.8,7.7-1.9,8.3-1.9c2.9,0.4,2.5,2.8,2.3,4.1c0,0.2-0.1,0.4-0.1,0.6c-0.1,1.5-1.9,2.5-4.6,2.5c-0.4,0-0.5,0.1-0.5,0.1
		c0,0.1,0,0.6,0.1,1c0.1,0.6,0.2,1.4,0.2,2.4c0,1-0.4,1.8-1.2,2.3C140.3,365.9,139.5,366.1,138.5,366.1z M144.7,353.8
		c-2.2,0-6.3,0.4-7.3,1.5c-0.2,0.2-0.2,0.3-0.2,0.5c0,1,0.3,1.3,0.6,1.7c0.6,0.7,0.6,1.3-0.3,2.5c-0.3,0.4-0.6,0.7-0.9,1.1
		c-0.8,0.9-1.7,1.9-1.6,2.4c0,0.1,0,0.3,0.5,0.5c0.8,0.5,2.1,0.8,3.1,0.8l0,0c0.7,0,1.3-0.1,1.7-0.4c0.4-0.3,0.6-0.7,0.6-1.2
		c0-0.9-0.1-1.6-0.2-2.2c-0.1-0.9-0.2-1.6,0.2-2.1c0.4-0.4,1-0.5,1.5-0.5c1.6,0,3.3-0.4,3.4-1.3c0-0.2,0.1-0.4,0.1-0.7
		C146.1,354.7,146,354,144.7,353.8z"
      />
      <path
        fill="#231F20"
        d="M172.8,367.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0-1.7-1.1-2-2.7c-0.2-0.9,0.1-1.8,0.8-2.6c1.5-1.9,5.1-2.2,7.1-1.2
		c1.5,0.8,2.2,2.1,1.8,3.8c-0.1,0.3-0.4,0.6-0.8,0.5c-0.3-0.1-0.6-0.4-0.5-0.8c0.3-1-0.1-1.8-1.1-2.3c-1.7-0.9-4.5-0.4-5.5,0.8
		c-0.4,0.6-0.6,1.1-0.5,1.6c0.2,1.1,1.3,1.8,1.4,1.8c0.3,0.2,0.4,0.6,0.2,0.9C173.2,367.2,173,367.3,172.8,367.3z"
      />
      <path
        fill="#231F20"
        d="M179.5,326.7c-0.9,0-1.8-0.3-2.7-0.8c-2.7-1.7-2.7-3.2-2.7-5.1c0-0.4,0-0.9,0-1.4c0-1.1-0.4-1.2-2.9-1.2
		c-1,0-2.4,0-3.8-0.2c-1.6-0.2-2.8-0.9-3.5-1.9c-0.9-1.3-0.7-2.7-0.6-2.8c0.1-0.4,0.4-0.6,0.8-0.5c0.4,0.1,0.6,0.4,0.5,0.8
		c0,0-0.2,1,0.4,1.8c0.5,0.7,1.4,1.1,2.6,1.3c1.3,0.2,2.5,0.2,3.6,0.2c2.1,0,4.1,0,4.2,2.4c0,0.5,0,1,0,1.5c0,1.8,0,2.7,2.1,4
		c1.4,0.9,2.5,0.7,3.2,0.4c0.9-0.4,1.6-1.3,1.7-2.2c0.1-0.4,0.4-0.6,0.8-0.5c0.4,0.1,0.6,0.4,0.5,0.8c-0.2,1.4-1.2,2.6-2.5,3.2
		C180.6,326.5,180.1,326.7,179.5,326.7z"
      />
    </g>
  </svg>
);
