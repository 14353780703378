import React from 'react';
import ReactDOM from 'react-dom';

import {from} from 'apollo-link';

import {ApolloClient} from 'apollo-client';
import {onError} from 'apollo-link-error';
import {HttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloProvider} from 'react-apollo';

import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';

import {API_URI} from './config';

import Invoice from './routes/Invoice';
import Invoices from './routes/Invoices';
import Login from './routes/Login';
import ChangePassword from './routes/ChangePassword';
import Dashboard from './routes/Dashboard';
import PageNotFound from './routes/PageNotFound';
import Clients from './routes/Clients';
import Ticket from './routes/Ticket';
import Tickets from './routes/Tickets';
import NewTicket from './routes/NewTicket';
import KB from './routes/KB';
import AccountDeletion from './routes/AccountDeletion';

import BuyVPN from './routes/BuyVPN';
import Pay from './routes/Pay';

const httpLink = new HttpLink({
  uri: API_URI
});

// get the authentication token from local storage if it exists
// and include it into our graphql requests
const authLink = setContext((_, {headers}) => {
  const token = window.localStorage.getItem('jwt');
  console.log(`Using token ${token}`);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const debugLink = onError(
  ({operation, response, graphQLErrors, networkError, forward}) => {
    if (graphQLErrors) {
      // const errType = graphQLErrors[0].message || false;
      // INVALID_TOKEN
      /*
            if (errType === 'INVALID_TOKEN') {
              const refreshToken = async () => {
                localStorage.removeItem('token')
                console.log('Requesting new token after error')
                await getToken()
              }
              refreshToken().then(() => {
                return forward(operation)
              })
            }
            */
    }
    console.log('apolloError', {
      operation,
      response,
      graphQLErrors,
      networkError
    });
  }
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  link: from([authLink, debugLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions
});

// we already have a token so we can render
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/kb/:kbId" component={KB} />
        <Route exact path="/invoice/:invoiceId" component={Invoice} />
        <Route exact path="/invoices" component={Invoices} />
        <Route exact path="/tickets" component={Tickets} />
        <Route exact path="/tickets/new" component={NewTicket} />
        <Route exact path="/ticket/:ticketId" component={Ticket} />
        <Route exact path="/vpn-clients" component={Clients} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/delete-account" component={AccountDeletion} />
        <Route exact path="/buy/:plan?/:processor?" component={BuyVPN} />
        <Route
          exact
          path="/pay/:invoiceId/:type?/:processor?"
          component={Pay}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  </ApolloProvider>,

  document.getElementById('root')
);
