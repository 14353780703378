import React from 'react';
import {Box, Heading} from 'grommet';
import styled from 'styled-components';
import rem from '../utils/rem';
import {SubHeader} from './Layout';

const StyledBox = styled(Box)`
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  color: #212b36;
  padding-left: ${rem('20px')};
  padding-right: ${rem('20px')};

  && > * + * {
    margin-top: ${rem('20px')};
  }
`;

const TitleWrapper = ({children}) => (
  <Heading margin={{top: 'small', bottom: 'none'}} level="3">
    {children}
  </Heading>
);

const Card = ({children, title, ...rest}) => {
  return (
    <StyledBox
      background="#fff"
      pad="small"
      round="xxsmall"
      margin="small"
      {...rest}
    >
      {title ? <TitleWrapper>{title}</TitleWrapper> : null}
      {children}
    </StyledBox>
  );
};

export default Card;
