import React from 'react';
import {ClientsPage, AppWrapper} from '../components';

const HelpdeskRoute = () => {
  return (
    <AppWrapper>
      <ClientsPage />
    </AppWrapper>
  );
};

export default HelpdeskRoute;
