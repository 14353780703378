import React from 'react';

import {
  Table,
  TableBody,
  Box,
  Text,
  TableFooter,
  TableHeader,
  TableRow,
  TableCell
} from 'grommet';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {AppSkeleton} from '..';

import Card from '../Card';
import LineItem from './LineItem';
import Header from './Header';
import AmountDue from './AmountDue';
import PayInvoice from './Pay';
import RibbonPaid from './RibbonPaid';
import RibbonUnpaid from './RibbonUnpaid';

const InvoiceCard = ({invoiceQuery: {loading, error, getInvoice}}) => {
  if (loading) {
    return <AppSkeleton />;
  }

  if (error) {
    return <Card>{error.message}</Card>;
  }

  if (!getInvoice) {
    return <Card>Something went wrong... maybe you do not have access ?</Card>;
  }

  return (
    <Card style={{position: 'relative'}}>
      <Header date={getInvoice.date} invoiceId={getInvoice.id} />

      <Box
        pad="medium"
        border={{side: 'top', size: 'xsmall', color: 'light-2'}}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell
                key="table-1"
                scope="col"
                border="bottom"
                align="start"
              >
                <Text size="small" color="light-5">
                  PRODUCT
                </Text>
              </TableCell>
              <TableCell key="table-2" scope="col" border="bottom" align="end">
                <Text size="small" color="light-5">
                  PRICE
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {getInvoice.trial && (
              <LineItem
                title="Promotional Code: -$3.99 USD One Time Discount"
                price="$-3.99"
              />
            )}

            {getInvoice.description && (
              <LineItem
                title={getInvoice.description}
                price={`$${getInvoice.lineCost}`}
              />
            )}
          </TableBody>
          <TableFooter>
            <AmountDue
              status={getInvoice.status}
              amount={getInvoice.balanceCurrency}
            />
          </TableFooter>
        </Table>
      </Box>

      {getInvoice.status === 'PAID' ? <RibbonPaid /> : <RibbonUnpaid />}

      {getInvoice.status !== 'PAID' && <PayInvoice invoiceId={getInvoice.id} />}
    </Card>
  );
};

export default graphql(
  gql`
    query InvoiceQuery($invoice: ID!) {
      getInvoice(id: $invoice) {
        id
        date
        status
        plan
        trial
        balance
        balanceCurrency
        description
        lineCost
      }
    }
  `,
  {
    name: 'invoiceQuery'
  }
)(InvoiceCard);
