import React from 'react';

import {Box} from 'grommet';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {AppSkeleton, Card, Title} from '..';

import './style.css';

const KBCard = ({kbQuery: {loading, error, getKnowledgebase}}) => {
  if (loading) {
    return <AppSkeleton />;
  }

  if (error) {
    return <Card>{error.message}</Card>;
  }

  return (
    <Box>
      <Title>{getKnowledgebase.title.replace('&#039;', "'")}</Title>
      <Card>
        <div
          className="content kbContent"
          dangerouslySetInnerHTML={{__html: getKnowledgebase.content}}
        />
      </Card>
    </Box>
  );
};

export default graphql(
  gql`
    query KBQuery($kb: ID!) {
      getKnowledgebase(kb: $kb) {
        id
        title
        content
      }
    }
  `,
  {
    name: 'kbQuery'
  }
)(KBCard);
