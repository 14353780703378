import React from 'react';
import {Page} from '..';
import TicketCard from './TicketCard';

const TicketPage = ({ticketId}) => {
  return (
    <Page>
      <TicketCard ticket={ticketId} />
    </Page>
  );
};

export default TicketPage;
