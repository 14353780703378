import React from 'react';
import {Box, Text} from 'grommet';
import {Notification} from 'grommet-icons';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  box-shadow: inset 0 3px 0 0 #637381, inset 0 0 0 0 transparent,
    0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  background-color: #f4f6f8;
`;

const Banner = ({children}) => {
  return (
    <StyledBox
      background="light-1"
      pad={{left: 'medium', top: 'xsmall', bottom: 'xsmall', right: 'xsmall'}}
    >
      <Box direction="row" gap="small" margin={{top: 'xsmall'}} align="start">
        <Box>
          <Notification color="dark-5" size="18px" />
        </Box>
        <Text color="dark-5" size="xsmall">
          {children}
        </Text>
      </Box>
    </StyledBox>
  );
};

export default Banner;
