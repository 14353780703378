import React from 'react';
import {ChangePasswordPage, AppWrapper} from '../components';

const ChangePasswordRoute = () => {
  return (
    <AppWrapper noFrame noAuth>
      <ChangePasswordPage />
    </AppWrapper>
  );
};

export default ChangePasswordRoute;
