import React from 'react';

import {Box, Text, Anchor} from 'grommet';

import Button from '../Form/Button';

const PayBox = ({text, icon, subscribe, support, invoiceId, processor}) => {
  let link1 = `/pay/${invoiceId}/subscribe/${processor}`;
  const link2 = `/pay/${invoiceId}/one-time/${processor}`;

  if (!subscribe) {
    link1 = link2;
  }

  return (
    <Box
      fill
      pad="medium"
      align="center"
      background={{color: 'light-2', opacity: 'strong'}}
      round="0.5em"
      gap="small"
    >
      {icon}
      <Text textAlign="center">{text}</Text>
      <Button url={link1} full primary>
        {subscribe ? 'Subscribe' : 'Pay'}
      </Button>
      {subscribe ? (
        <Anchor
          href={link2}
          label="Pay w/o subscription"
          color="light-6"
          size="xsmall"
        />
      ) : (
        <Text textAlign="center" size="xsmall">
          <i>{support}</i>
        </Text>
      )}
    </Box>
  );
};

export default PayBox;
