import {grommet} from 'grommet/themes';
import {deepMerge} from 'grommet/utils';
import {brand as brandGreen, darkGrey} from './utils/colors';

export const API_URI = 'https://my.vpn.ht/graphql';
export const API_CHECK_USERNAME = 'https://api.vpn.ht/user?username=%s';
export const WHMCS_ROOT = 'https://my.vpn.ht';
export const GOOGLE_MAP_API = 'AIzaSyBX4t3fb9lf1gf2fw9_yUfVM2wTUxTf7eI';

export const THEME_CONFIG = deepMerge(grommet, {
  global: {
    colors: {
      brand: brandGreen,
      'dark-1': '#2b2b2b',
      'dark-2': darkGrey,
      'accent-1': '#00bab8',
      'accent-2': '#FEAD9A'
    },
    font: {
      family: 'Roboto',
      size: '14px'
    }
  },
  button: {
    border: {
      radius: '4px'
    }
  }
});
