import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';
import {Box} from 'grommet';
import {TextArea, Button, Banner, Select} from '..';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {withRouter} from 'react-router-dom';

class NewTicketForm extends Component {
  onSubmitLoginForm = async ({fields: {subject, content}}) => {
    const {ticketMutation, history} = this.props;

    try {
      const {data} = await ticketMutation({
        variables: {subject: subject.value, content: content.value}
      });

      if (data.createTicket && data.createTicket.id) {
        history.push(`/ticket/${data.createTicket.id}`);
      } else {
        // return error message
        return [{message: 'Unable to create ticket'}];
      }
    } catch (error) {
      return error.graphQLErrors;
    }
  };

  render() {
    const {banner} = this.props;
    return (
      <FormState
        validateOnSubmit
        initialValues={{
          content: '',
          subject: 'Other'
        }}
        onSubmit={this.onSubmitLoginForm}
        validators={{
          subject(input) {
            if (input === '') {
              return "Subject can't be blank";
            }
            return null;
          },
          content(input) {
            if (input === '') {
              return "Support message can't be blank";
            }
            return null;
          }
        }}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 ? (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              ) : (
                banner && <Banner status="critical">{banner}</Banner>
              )}

              <Box fill align="left">
                <Select
                  style={{width: '100%'}}
                  label="Plan"
                  options={[
                    {
                      name: 'Invalid credentials',
                      id: 'Invalid credentials'
                    },
                    {name: 'Refund Request', id: 'Refund Request'},
                    {name: 'Delete account', id: 'Delete account'},
                    {name: 'Other', id: 'Other'}
                  ]}
                  {...fields.subject}
                />
              </Box>
              <TextArea
                style={{height: '200px'}}
                fill
                label="Text"
                placeholder="Give much information as you can..."
                {...fields.content}
              />
              <Box direction="row" gap="small" margin={{top: 'small'}}>
                <Button loading={submitting} submit primary>
                  Submit
                </Button>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default graphql(
  gql`
    mutation TicketMutation($subject: String!, $content: String!) {
      createTicket(subject: $subject, content: $content) {
        id
      }
    }
  `,
  {
    name: 'ticketMutation'
  }
)(withRouter(NewTicketForm));
