import React from 'react';
import {Button, Box} from 'grommet';

import {Link} from 'react-router-dom';

import styled from 'styled-components';
import {darkGrey, brand as brandGreen} from '../../utils/colors';

import rem from '../../utils/rem';

const StyledButton = styled(Button)`
  background-color: ${(p) => (p.primary === true ? brandGreen : null)};
  color: ${(p) => (p.primary ? '#fff' : darkGrey)};
  border-color: ${(p) => (p.primary === true ? brandGreen : null)};
  ${(p) =>
    (p.size === 'large' ? `font-size: ${rem(40)}; padding: ${rem(25)};` : '')};
`;

const StyledLink = styled.div`
  a {
    text-decoration: none;
  }
`;

const ShouldLink = ({url, external, children}) => {
  if (external && url) {
    return (
      <StyledLink>
        <a href={url}>{children}</a>
      </StyledLink>
    );
  }
  if (url) {
    return (
      <StyledLink>
        <Link to={url}>{children}</Link>
      </StyledLink>
    );
  }
  return children;
};

const ButtonComponent = ({
  primary,
  full,
  loading,
  disabled,
  submit,
  children,
  external,
  url,
  ...rest
}) => {
  return (
    <ShouldLink url={url} external={external}>
      <Box
        gap={full ? null : 'small'}
        align={full ? null : 'start'}
        margin={full ? 'small' : {top: 'xsmall', bottom: 'xsmall'}}
      >
        <StyledButton
          type={submit ? 'submit' : 'button'}
          label={children}
          disabled={loading || disabled}
          primary={primary}
          color={primary ? 'brand' : null}
          {...rest}
        />
      </Box>
    </ShouldLink>
  );
};

export default ButtonComponent;
