import React from 'react';
import {LoginPage, AppWrapper} from '../components';

const LoginRoute = () => {
  return (
    <AppWrapper noFrame noAuth>
      <LoginPage />
    </AppWrapper>
  );
};

export default LoginRoute;
