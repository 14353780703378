import React from 'react';
import {Box} from 'grommet';

import {Title} from './Layout';

const Page = ({title, full, children}) => {
  return (
    <Box>
      {title && <Title>{title}</Title>}
      {children}
    </Box>
  );
};

export default Page;
