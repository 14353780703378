import React from 'react';
import {Page, Card} from '..';
import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <Page title="Please, login.">
      <Card>
        <LoginForm />
      </Card>
    </Page>
  );
};

export default LoginPage;
