import React from 'react';
import {Box, Anchor, Text} from 'grommet';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {Monster1} from '../../monsters';
import Sidebar from './Sidebar';
import SidebarMenus from './SidebarMenus';
import MobileNav from './MobileNavbar';

const Nav = (props) => {
  const {
    showSideNav,
    isSideFolded,
    onSideToggle,
    customerQuery: {customer, loading}
  } = props;

  if (loading) {
    // return <AppSkeleton />;
  }

  const {pathname} = props.history.location;

  return (
    <div>
      {showSideNav !== false && (
        <MobileNav isSideFolded={isSideFolded} onSideToggle={onSideToggle} />
      )}
      {showSideNav !== false && (
        <Sidebar isFolded={isSideFolded}>
          <Box pad={{vertical: 'small', horizontal: 'small'}}>
            <Anchor
              onClick={() => {
                props.history.push('/');
              }}
              color="brand"
              icon={<Monster1 />}
              label={
                <Text color="brand" size="xlarge">
                  VPN.ht portal
                </Text>
              }
            />
          </Box>
          <SidebarMenus
            currentPath={pathname}
            history={props.history}
            invoicesDue={customer ? customer.invoicesDue : 0}
            activeTickets={customer ? customer.activeTickets : 0}
          />
          <Box>Connected as:</Box>
        </Sidebar>
      )}
    </div>
  );
};

export default graphql(
  gql`
    query CustomerQuery {
      customer {
        email
        invoicesDue
        activeTickets
        amountDue
        email
      }
    }
  `,
  {
    name: 'customerQuery'
  }
)(withRouter(Nav));
