import React from 'react';
import {PayPage, AppWrapper} from '../components';

const BuyVPNRoute = ({
  match: {
    params: {invoiceId}
  }
}) => {
  return (
    <AppWrapper noFrame noAuth>
      <PayPage invoiceId={parseInt(invoiceId, 0)} />
    </AppWrapper>
  );
};

export default BuyVPNRoute;
