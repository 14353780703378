import React from 'react';
import {Button, Box, Text} from 'grommet';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Badge} from '..';

const StyledBox = styled(Box)`
  background-color: ${(props) =>
    (props.active ? 'hsla(0, 0%, 100%, 0.75)' : 'transparent')};

  :hover {
    background-color: hsla(0, 0%, 100%, 0.75);
  }
`;

const StyledWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const EmptyDiv = styled.div;

const MenuItem = ({path, icon, active, label, badge, onClick}) => {
  let LinkWrapper = React.Fragment;
  let linkProps = {};
  if (path) {
    LinkWrapper = Link;
    linkProps = {to: path};
  }

  return (
    <Button key={label} onClick={onClick}>
      <StyledWrapper>
        <LinkWrapper {...linkProps}>
          <StyledBox
            active={active}
            pad={{horizontal: 'medium', vertical: 'small'}}
          >
            <Box pad="xxsmall" direction="row" align="center" gap="small">
              {icon}
              <Text color={active ? 'brand' : 'dark-5'}>{label}</Text>
              {badge ? <Badge>{badge}</Badge> : null}
            </Box>
          </StyledBox>
        </LinkWrapper>
      </StyledWrapper>
    </Button>
  );
};

export default MenuItem;
