import React from 'react';
import {Box} from 'grommet';
import {Card, SkeletonBodyText, Title} from '..';

const KBLoading = () => {
  return (
    <Box margin={{top: 'medium'}}>
      <Title>Documentations</Title>
      <Box
        gap="medium"
        direction="row-responsive"
        justify="center"
        align="start"
      >
        <Card fill>
          <SkeletonBodyText lines={5} />
        </Card>
        <Card fill>
          <SkeletonBodyText lines={5} />
        </Card>
      </Box>
    </Box>
  );
};

export default KBLoading;
