import React from 'react';
import {Box} from 'grommet';
import {Page} from '..';
import ClientsList from '../Helpdesk/ClientsList';
import KBCategories from './KBCategories';
import Account from './Account';

const DashboardPage = () => {
  return (
    <Page>
      <Box style={{width: '1024px', margin: '0 auto'}}>
        <Account />
        <ClientsList />
        <KBCategories />
      </Box>
    </Page>
  );
};

export default DashboardPage;
