import React from 'react';
import {KBPage, AppWrapper} from '../components';

const TicketRoute = ({match}) => {
  return (
    <AppWrapper>
      <KBPage kbId={match.params.kbId} />
    </AppWrapper>
  );
};

export default TicketRoute;
