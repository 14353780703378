import React, {Component} from 'react';

import {UserExpert, User} from 'grommet-icons';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {Box, Text, Markdown} from 'grommet';
import {AppSkeleton, Card, Title} from '..';
import styled from 'styled-components';

import rem from '../../utils/rem';

import {WHMCS_ROOT} from '../../config';

import UnknownFile from './UnknownFile';
import TicketReplyForm from './TicketReplyForm';

const StyledReply = styled(Box)`
  padding-top: ${rem(19)};
  font-size: ${rem(19)};
  p {
    font-size: ${rem(19)};
    padding-top: 0px;
    max-width: 100%;
  }
`;

const StyledThumb = styled.div`
  max-height: 150px;
  padding: 10px;
  display: block;
`;

const StyledImage = styled.img`
  background-color: #fff;
  border: 1px solid #ccc;
  text-decoration: none;
  max-height: 127px;
  display: block;
`;

class TicketCard extends Component {
  state = {
    latestRef: React.createRef()
  };

  render() {
    const {
      ticketQuery: {refetch, loading, error, getTicket}
    } = this.props;

    const {latestRef} = this.state;

    if (loading) {
      return <AppSkeleton />;
    }

    if (error) {
      return <Card>{error.message}</Card>;
    }

    window.scrollTo({
      top: 1000,
      behavior: 'smooth'
    });

    const onNewReply = async () => {
      await refetch();
      window.scrollTo({
        top: this.state.latestRef.current.offsetTop,
        behavior: 'smooth'
      });
    };

    const repliesMarkup = [];

    getTicket.replies.forEach((reply, key) => {
      const backgroundColor = reply.customer ? '#fff' : '#faf8f1';
      const textColor = 'dark-3';
      let isLast = false;
      if (key === getTicket.replies.length - 1) {
        // attach the ref tp this box...
        isLast = true;
        console.log(latestRef);
      }
      repliesMarkup.push(
        <Card background={backgroundColor} key={key}>
          {isLast && <div ref={latestRef} />}
          <Box direction="row" gap="small" margin={{top: 'small'}}>
            {reply.customer ? (
              <User color={textColor} />
            ) : (
              <UserExpert color={textColor} />
            )}
            <Text color={textColor} size="small">
              Sent by {reply.customer ? 'You' : reply.adminName} on {reply.date}
            </Text>
          </Box>
          <StyledReply
            border={{side: 'top', size: 'xsmall', color: 'light-3'}}
          >
            <Markdown color={textColor}>
              {reply.message
                .replace(/&amp;/g, '&')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&#039;/g, "'")
                .replace(/&quot;/g, '"')}
            </Markdown>

            {reply.attachment && reply.attachmentType === 'IMAGE' && (
              <Box margin="medium">
                <Text color={textColor} size="small">
                  Attachment:
                </Text>
                <StyledThumb>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${WHMCS_ROOT}/attachments/${reply.attachment}`}
                  >
                    <StyledImage
                      alt=""
                      src={`${WHMCS_ROOT}/attachments/${reply.attachment}`}
                    />
                  </a>
                </StyledThumb>
              </Box>
            )}

            {reply.attachment && reply.attachmentType === 'FILE' && (
              <Box margin="medium">
                <Text
                  color={textColor}
                  size="small"
                  margin={{bottom: 'small'}}
                >
                  Attachment:
                </Text>
                <StyledThumb>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${WHMCS_ROOT}/attachments/${reply.attachment}`}
                  >
                    <UnknownFile size="small" />
                  </a>
                </StyledThumb>
              </Box>
            )}
          </StyledReply>
        </Card>
      );
    });

    return (
      <Box>
        <Title>{getTicket.subject.replace(/&amp;/g, '&')
          .replace(/&gt;/g, '>')
          .replace(/&lt;/g, '<')
          .replace(/&#039;/g, "'")
          .replace(/&quot;/g, '"')}</Title>

        <Card>
          <TicketReplyForm ticket={getTicket.id} onNewReply={onNewReply} />
        </Card>

        {repliesMarkup}
      </Box>
    );
  }
}

export default graphql(
  gql`
    query TicketQuery($ticket: ID!) {
      getTicket(ticket: $ticket) {
        id
        date
        lastReply
        status
        subject
        replies {
          customer
          date
          message
          adminName
          attachment
          attachmentType
        }
      }
    }
  `,
  {
    name: 'ticketQuery'
  }
)(TicketCard);
