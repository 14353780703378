import React from 'react';
import {InvoicePage, AppWrapper} from '../components';

const InvoiceRoute = ({match}) => {
  return (
    <AppWrapper>
      <InvoicePage invoiceId={match.params.invoiceId} />
    </AppWrapper>
  );
};

export default InvoiceRoute;
