import React from 'react';
import {Page} from '..';
import InvoiceCard from './InvoiceCard';

const InvoicePage = ({invoiceId}) => {
  return (
    <Page>
      <InvoiceCard invoice={invoiceId} />
    </Page>
  );
};

export default InvoicePage;
