import React from 'react';
import {Box, DataTable, Text} from 'grommet';
import {Support} from 'grommet-icons';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import KBLoading from './KBLoading';
import KBCard from './KBCard';
import {Title, Card} from '..';

const KBCategories = ({kbQuery}) => {
  const {loading, getKnowledgebases, errors} = kbQuery;

  const kbs = [];

  console.log(errors);

  if (loading) {
    return <KBLoading />;
  }

  if (errors) {
    return <Card>{errors}</Card>;
  }

  if (!loading && getKnowledgebases) {
    getKnowledgebases.forEach((category, id) => {
      kbs.push(<KBCard category={category} key={`cat-${id}`} />);
    });
  }
  return (
    <Box margin={{top: 'medium'}}>
      <Title>Documentations</Title>
      <Box
        gap="medium"
        direction="row-responsive"
        justify="center"
        align="start"
      >
        {kbs}
      </Box>
    </Box>
  );
};

export default graphql(
  gql`
    query KBQuery {
      getKnowledgebases {
        name
        articles {
          id
          title
        }
      }
    }
  `,
  {
    name: 'kbQuery'
  }
)(KBCategories);
