import React from 'react';
import {Page, Button} from '..';

const InvoicePage = () => {
  return (
    <Page title="Page not found.">
      <Button plain url="/">
        ← Back home...
      </Button>
    </Page>
  );
};

export default InvoicePage;
