import React from 'react';
import {Page, Card} from '..';
import NewTicketForm from './NewTicketForm';

const HelpdeskPage = () => {
  return (
    <Page title="New ticket">
      <Card>
        <NewTicketForm />
      </Card>
    </Page>
  );
};

export default HelpdeskPage;
