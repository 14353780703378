import React from 'react';
import {Page} from '..';
import TicketsCard from './TicketsCard';

const HelpdeskPage = () => {
  return (
    <Page>
      <TicketsCard />
    </Page>
  );
};

export default HelpdeskPage;
