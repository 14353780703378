import React from 'react';
import {Grid, Box, Text, Stack} from 'grommet';

const LineItem = ({date, invoiceId}) => {
  return (
    <Grid
      fill
      pad="small"
      rows={['flex']}
      columns={['small', 'flex']}
      areas={[
        {name: 'left', start: [0, 0], end: [0, 0]},
        {name: 'right', start: [1, 0], end: [1, 0]}
      ]}
    >
      <Box gridArea="left" pad="small">
        <Text size="xlarge">INVOICE</Text>
        <Text size="small" color="light-5">
          {date}
        </Text>
      </Box>
      <Box gridArea="right" align="end" pad="small">
        <Stack>
          <Text size="xxlarge">#{invoiceId}</Text>
        </Stack>
      </Box>
    </Grid>
  );
};

export default LineItem;
