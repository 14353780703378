import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';
import {Box} from 'grommet';
import {TextArea, Button, Banner} from '..';
import gql from 'graphql-tag';
import {graphql, compose} from 'react-apollo';
import {withRouter} from 'react-router-dom';

class TicketReplyForm extends Component {
  onSubmitLoginForm = async ({fields: {content}}) => {
    const {ticketMutation, ticket, onNewReply} = this.props;

    try {
      const {data} = await ticketMutation({
        variables: {content: content.value, ticket}
      });

      if (data.createTicketReply && data.createTicketReply.id) {
        onNewReply();
        content.onChange('');
      } else {
        // return error message
        return [{message: 'Unable to create ticket'}];
      }
    } catch (error) {
      return error.graphQLErrors;
    }
  };

  closeTicket = async () => {
    const {closeTicketMutation, ticket, history} = this.props;
    try {
      await closeTicketMutation({
        variables: {ticket}
      });
      history.push('/tickets');
    } catch (error) {
      console.log(error.graphQLErrors);
    }
  };

  render() {
    const {banner} = this.props;
    return (
      <FormState
        validateOnSubmit
        initialValues={{
          content: ''
        }}
        onSubmit={this.onSubmitLoginForm}
        validators={{
          content(input) {
            if (input === '') {
              return "Support message can't be blank";
            }
            return null;
          }
        }}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 ? (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              ) : (
                banner && <Banner status="critical">{banner}</Banner>
              )}

              <TextArea
                style={{height: '200px'}}
                fill
                label="Text"
                placeholder="Give much information as you can..."
                {...fields.content}
              />
              <Box direction="row" gap="small" margin={{top: 'small'}}>
                <Button loading={submitting} submit primary>
                  Submit reply
                </Button>
                <Button color="light-2" onClick={this.closeTicket}>
                  Mark as resolved
                </Button>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default compose(
  graphql(
    gql`
      mutation TicketMutation($ticket: String!, $content: String!) {
        createTicketReply(ticket: $ticket, content: $content) {
          id
        }
      }
    `,
    {
      name: 'ticketMutation'
    }
  ),
  graphql(
    gql`
      mutation CloseTicketMutation($ticket: String!) {
        closeTicket(ticket: $ticket) {
          id
        }
      }
    `,
    {
      name: 'closeTicketMutation'
    }
  )
)(withRouter(TicketReplyForm));
