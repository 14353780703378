import React from 'react';
import styled, {keyframes} from 'styled-components';
import {paleGrey} from '../../utils/colors';
import rem from '../../utils/rem';

const BodyContainer = styled.div`
  backface-visibility: hidden;
  will-change: opacity;
`;

const animation = keyframes`
    from {
        opacity: 0.45
    }

    to {
        opacity: 0.9
    }
`;

const BodyText = styled.div`
  height: ${rem('14px')};
  position: relative;
  animation: ${animation} 800ms linear infinite alternate;
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${paleGrey};
    border-radius: 3px;
  }

  && + && {
    margin-top: ${rem('20px')};
  }

  :last-child:not(:first-child) {
    width: 80%;
  }
`;

const SkeletonBodyText = ({lines = 3}) => {
  const linesJsx = [];
  for (let index = 0; index < lines; index++) {
    linesJsx.push(<BodyText key={index} />);
  }
  return <BodyContainer>{linesJsx}</BodyContainer>;
};

export default SkeletonBodyText;
