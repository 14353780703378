import React from 'react';

import {Page, Card, SkeletonHeader, SkeletonBodyText} from '..';

const AppSkeleton = () => {
  return (
    <Page>
      <Card>
        <SkeletonHeader />
        <SkeletonBodyText lines="10" />
      </Card>
    </Page>
  );
};

export default AppSkeleton;
