import React from 'react';
import styled from 'styled-components';
import {Box} from 'grommet';

import {
  Home,
  Money,
  Support,
  License,
  Logout,
  CloudSoftware
} from 'grommet-icons';

import rem from '../../utils/rem';

import MenuItem from './MenuItem';

const MenuInner = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100%;
  padding-top: ${rem(5)};
`;

const SidebarMenus = ({currentPath, history, invoicesDue, activeTickets}) => {
  const signout = () => {
    localStorage.removeItem('jwt');
    history.push('/login');
  };

  return (
    <MenuInner>
      <Box>
        <MenuItem
          label="Home"
          path="/"
          active={currentPath === '/'}
          icon={<Home />}
        />

        <MenuItem
          label="Invoices"
          path="/invoices"
          active={currentPath.search('/invoice') !== -1}
          icon={<Money />}
          badge={invoicesDue}
        />

        <MenuItem
          label="Helpdesk"
          path="/tickets"
          icon={<Support />}
          active={currentPath.search('/ticket') !== -1}
          badge={activeTickets}
        />

        <MenuItem
          label="Change password"
          path="/change-password"
          active={currentPath.search('/password') !== -1}
          icon={<License />}
        />

        <MenuItem label="Sign out" onClick={signout} icon={<Logout />} />
      </Box>
    </MenuInner>
  );
};

export default SidebarMenus;
