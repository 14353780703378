import React, {useContext} from 'react';
import {Title, Button, Card, SkeletonBodyText} from '..';
import {Box, DataTable, Text, ResponsiveContext} from 'grommet';
import {Support} from 'grommet-icons';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

const TicketsCard = ({ticketsQuery}) => {
  const {loading, getTickets} = ticketsQuery;
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';

  let columns = [
    {
      property: 'description',
      primary: true,
      render: (data) =>
        data && (
          <Box>
            <Text truncate>
              {data.description
                .replace(/&amp;/g, '&')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&#039;/g, "'")
                .replace(/&quot;/g, '"')}
            </Text>
          </Box>
        )
    },
    {
      property: 'date',
      align: 'end'
    },
    {
      property: 'actions',
      align: 'end',
      render: (data) =>
        data && (
          <Button
            url={`/ticket/${data.actions}`}
            primary={data.status === 'ANSWERED'}
            color={data.status === 'CLOSED' ? 'accent-2' : 'light-2'}
          >
            {data.status.charAt(0).toUpperCase() +
              data.status.slice(1).toLowerCase()}
          </Button>
        )
    }
  ];

  if (isMobile) {
    columns = [
      {
        property: 'description',
        primary: true,
        render: (data) =>
          data && (
            <Box>
              <Text truncate>
                {data.description
                  .replace(/&amp;/g, '&')
                  .replace(/&gt;/g, '>')
                  .replace(/&lt;/g, '<')
                  .replace(/&#039;/g, "'")
                  .replace(/&quot;/g, '"')}
              </Text>
            </Box>
          )
      },
      {
        property: 'actions',
        align: 'end',
        render: (data) =>
          data && (
            <Button
              url={`/ticket/${data.actions}`}
              primary={data.status === 'ANSWERED'}
              color={data.status === 'CLOSED' ? 'accent-2' : 'light-2'}
            >
              {data.status.charAt(0).toUpperCase() +
                data.status.slice(1).toLowerCase()}
            </Button>
          )
      }
    ];
  }

  const data = [];
  if (!loading && getTickets) {
    getTickets.forEach((ticket) => {
      data.push({
        description: ticket.subject,
        date: ticket.lastReply,
        status: ticket.status,
        actions: ticket.id
      });
    });
  }
  return (
    <Box>
      <Box direction="row">
        <Title>Tickets</Title>
        <Button
          margin={{top: 'small'}}
          primary
          url="/tickets/new"
          icon={<Support size="medium" />}
        >
          New
        </Button>
      </Box>
      <Card>
        {loading ? (
          <SkeletonBodyText lines="5" />
        ) : data.length > 0 ? (
          <DataTable columns={columns} data={data} size="100%" />
        ) : (
          'No tickets found.'
        )}
      </Card>
    </Box>
  );
};

export default graphql(
  gql`
    query TicketsQuery {
      getTickets {
        id
        date
        lastReply
        subject
        status
      }
    }
  `,
  {
    name: 'ticketsQuery'
  }
)(TicketsCard);
