import styled, {css} from 'styled-components';
import rem from '../utils/rem';
import {tablet} from '../utils/media';
import {sidebarWidth} from '../utils/sizes';
import {bodyFont, headerFont} from '../utils/fonts';
import {mainGrey} from '../utils/colors';

export const Container = styled.div`
  padding-left: ${(p) => (p.showSideNav === false ? 0 : rem(sidebarWidth))};
  background-color: ${mainGrey};
  ${tablet(css`
    padding-left: 0;
  `)};
`;

const limitedSize = (width) => {
  return `
    width: ${width ? rem(width) : rem(1024)};
    padding: ${rem(40)} ${rem(40)} ${rem(30)} ${rem(40)};
`;
};

export const Content = styled.div`
  ${(props) => !props.full && props.width && limitedSize(props.width)}
  ${(props) => props.full && 'width: 100%;'}

  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  box-sizing: border-box;
  font-family: ${bodyFont};
  transition: transform 150ms ease-out;
  ${tablet(css`
    padding: ${rem(70)} ${rem(20)} ${rem(30)} ${rem(20)};
    transform: translateX(${(p) => (p.moveRight ? rem(sidebarWidth) : 0)});
  `)};
`;

export const Title = styled.h1`
  display: block;
  text-align: left;
  width: 100%;
  color: #00a6a3;
  font-size: ${rem(42)};
  line-height: ${rem(42)};
  font-weight: bold;
  font-family: ${headerFont};
`;

export const Header = styled.h2`
  font-size: ${rem(32)};
  font-weight: 600;
  font-family: ${headerFont};
  margin: 2em 0 0.75em;
`;

export const SubHeader = styled.h3`
  display: block;
  margin: 2em 0 0.75em;
  font-size: ${rem(24)};
  font-weight: 600;
  font-family: ${headerFont};
`;

export const TertiaryHeader = styled.h4`
  display: block;
  margin: 2em 0 0.75em;
  font-size: ${rem(18)};
  font-weight: 600;
  font-family: ${headerFont};
`;
