import React, {Component} from 'react';

import * as PropTypes from 'prop-types';

import AppFrame from './AppFrame';

class AppWrapper extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired
    })
  };

  shouldRedirect() {
    const {router} = this.context;
    const token = window.localStorage.getItem('jwt');

    if (!token) {
      if (router.history.location.pathname !== '/') {
        router.history.push(`/login?from=${router.history.location.pathname}`);
      } else {
        router.history.push('/login');
      }
    }
  }

  render() {
    const {children, noFrame, noAuth, fullWidth, width} = this.props;
    const toReturn = (
      <AppFrame full={fullWidth} width={width} showSideBar={!noFrame}>
        {children}
      </AppFrame>
    );

    if (!noAuth) {
      this.shouldRedirect();
    }

    return toReturn;
  }
}

export default AppWrapper;
