import React from 'react';
import {Page, Card, Button} from '..';
import {withRouter} from 'react-router-dom';
import ConfirmDeletion from './ConfirmDeletion';

const ChangePasswordPage = () => {
  return (
    <Page title="Confirmation.">
      <Card>
        <ConfirmDeletion />
      </Card>
      <Button
        url="/"
        size="small"
        plain
        margin={{top: 'medium', left: 'small'}}
      >
        ← Back home...
      </Button>
    </Page>
  );
};
export default withRouter(ChangePasswordPage);
