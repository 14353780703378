import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'grommet'
import { Menu, Close } from 'grommet-icons'
import { tablet } from '../../utils/media'
import { navbarHeight } from '../../utils/sizes'
import rem from '../../utils/rem'

const Wrapper = styled.div`
  display: none;
  ${tablet(css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${rem(navbarHeight)};
  `)};
`
const Topbar = styled.div`
  ${tablet(css`
    position: fixed;
    left: 0;
    box-sizing: border-box;
    z-index: 3;
    width: 100%;
    height: ${rem(navbarHeight)};
    font-size: ${rem(15)};
    font-weight: 500;
    transition: background 300ms ease-out;
    color: white;
    padding: 0;
  `)};
`

const MobileNav = ({ isSideFolded, onSideToggle }) => {
  return (
    <Wrapper>
      <Topbar>
        <Button
          plain
          icon={isSideFolded ? <Menu /> : <Close />}
          active={!isSideFolded}
          onClick={onSideToggle}
        />
      </Topbar>
    </Wrapper>
  )
}

export default MobileNav
