import React from 'react';
import {TextArea, Box, Text} from 'grommet';
import {Notification} from 'grommet-icons';

import styled from 'styled-components';
import {paleGrey, brand as brandGreen} from '../../utils/colors';

const StyledTextArea = styled(TextArea)`
  border-color: ${paleGrey};
  margin-top: '0.5em';
  :focus {
    border-color: ${brandGreen};
    border-width: 0.2em;
  }
`;

const StyledContainer = styled.div`
  margin-top: 1rem;
`;

const TextAreaComponent = ({
  onChange,
  value,
  name,
  placeholder,
  error,
  helpText,
  ...rest
}) => {
  return (
    <StyledContainer>
      <StyledTextArea
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        {...rest}
      />

      {helpText ? (
        <Box
          direction="row"
          gap="small"
          margin={{top: 'xsmall'}}
          align="start"
        >
          <Text size="xsmall">{helpText}</Text>
        </Box>
      ) : error ? (
        <Box
          direction="row"
          gap="small"
          margin={{top: 'xsmall'}}
          align="start"
        >
          <Box>
            <Notification color="#bf0711" size="18px" />
          </Box>
          <Text color="#bf0711" size="xsmall">
            {error}
          </Text>
        </Box>
      ) : null}
    </StyledContainer>
  );
};

export default TextAreaComponent;
