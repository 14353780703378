import React from 'react';
import {NewTicketPage, AppWrapper} from '../components';

const NewTicketRoute = () => {
  return (
    <AppWrapper>
      <NewTicketPage />
    </AppWrapper>
  );
};

export default NewTicketRoute;
