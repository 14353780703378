import React from 'react';
import {TicketsPage, AppWrapper} from '../components';

const HelpdeskRoute = () => {
  return (
    <AppWrapper>
      <TicketsPage />
    </AppWrapper>
  );
};

export default HelpdeskRoute;
