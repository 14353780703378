import React from 'react'
import { paleGrey } from '../../utils/colors'
import styled, { keyframes } from 'styled-components'
import rem from '../../utils/rem'

const animation = keyframes`
    from {
        opacity: 0.45
    }

    to {
        opacity: 0.9
    }
`

const HeaderText = styled.div`
  max-width: ${rem('200px')};
  height: ${rem('34px')};
  position: relative;
  animation: ${animation} 800ms linear infinite alternate;
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${paleGrey};
    border-radius: 3px;
  }
`

const SkeletonBodyText = () => {
  return <HeaderText />
}

export default SkeletonBodyText
