import React from 'react';
import {PageNotFound, AppWrapper} from '../components';

const Route404 = () => {
  return (
    <AppWrapper noFrame noAuth>
      <PageNotFound />
    </AppWrapper>
  );
};

export default Route404;
