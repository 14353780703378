import React from 'react';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {AppSkeleton} from '..';

const PayPage = ({paymentQuery: {getPaymentLink, loading, error}}) => {
  if (error) {
    if (error && error.networkError) {
      return <div>{error.networkError.message}</div>;
    }

    if (error && error.graphQLErrors) {
      return <div>{error.graphQLErrors[0].message}</div>;
    }

    return <div>Errors</div>;
  }

  if (getPaymentLink && !loading) {
    window.location.href = getPaymentLink;
  }

  return <AppSkeleton />;
};

export default graphql(
  gql`
    query GetPaymentQuery(
      $invoiceId: ID!
      $processor: PaymentMethod
      $subscription: Boolean
    ) {
      getPaymentLink(
        invoiceId: $invoiceId
        processor: $processor
        subscription: $subscription
      )
    }
  `,
  {
    name: 'paymentQuery'
  }
)(PayPage);
