import React from 'react';
import {TableRow, TableCell, Text} from 'grommet';

const AmountDue = ({amount, status}) => {
  return (
    <TableRow>
      <TableCell key="description" align="start">
        <Text size="small" color="light-5" margin={{top: 'large'}}>
          AMOUNT DUE:
        </Text>
      </TableCell>
      <TableCell key="amount" align="end">
        {status === 'UNPAID' && (
          <Text size="medium" color="dark-4" margin={{top: 'large'}}>
            {amount}
          </Text>
        )}

        {status === 'PAID' && (
          <Text size="medium" color="dark-4" margin={{top: 'large'}}>
            $0.00 USD
          </Text>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AmountDue;
