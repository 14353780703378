import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';
import {Box} from 'grommet';
import {TextInput, Button, Banner, SkeletonBodyText} from '..';
import gql from 'graphql-tag';
import {graphql, compose} from 'react-apollo';

import {withRouter} from 'react-router-dom';

import SendPasswordForm from './SendPasswordForm';

class NewPasswordForm extends Component {
  state = {
    passwordValid: false,
    passwordError: false,
    password2Error: false
  };

  onSubmitLoginForm = async ({fields: {password}}) => {
    const {passwordMutation, token} = this.props;
    const {passwordValid} = this.state;

    if (!passwordValid) {
      return [{message: 'Please provide a valid password.'}];
    }

    try {
      const {data} = await passwordMutation({
        variables: {password: password.value, token}
      });

      if (data.newPassword) {
        // force logout
        window.localStorage.removeItem('jwt');
        // redirect to the wanted page
        this.props.history.push('/login', {
          banner: 'Please login with new password'
        });
      } else {
        // return error message
        return [{message: 'Unable to reset password'}];
      }
    } catch (error) {
      return error.graphQLErrors;
    }
  };

  validatePassword(password, password2) {
    if (password.length < 6) {
      this.setState({
        passwordValid: false,
        passwordError: 'Password require 6 characters minumum.'
      });
      return;
    } else {
      this.setState({
        passwordValid: false,
        passwordError: false
      });
    }

    if (password === password2) {
      this.setState({
        passwordValid: true,
        passwordError: false,
        password2Error: false
      });
      return;
    }

    if (password2.length > 0) {
      this.setState({
        password2Error: 'Password do not match'
      });
      return;
    }

    this.setState({
      passwordValid: false,
      passwordError: false,
      password2Error: false
    });
  }

  render() {
    const {
      loading,
      valid,
      error,
      validatePasswordToken
    } = this.props.tokenQuery;

    const {passwordError, password2Error, passwordValid} = this.state;

    if (loading) {
      return <SkeletonBodyText lines={5} />;
    }

    if (!validatePasswordToken.valid) {
      return <SendPasswordForm banner={validatePasswordToken.error} />;
    }

    return (
      <FormState
        initialValues={{
          password: '',
          password2: ''
        }}
        onSubmit={this.onSubmitLoginForm}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          console.log(errors);
          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 && (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              )}

              <TextInput
                label="Password"
                placeholder="Enter your new password"
                type="password"
                {...fields.password}
                error={passwordError}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(password) => {
                  fields.password.onChange(password);
                  this.validatePassword(password, fields.password2.value);
                }}
              />

              <TextInput
                label="Confirm password"
                placeholder="Confirm your new password"
                type="password"
                {...fields.password2}
                error={password2Error}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(password) => {
                  fields.password2.onChange(password);
                  this.validatePassword(fields.password.value, password);
                }}
              />

              <Box direction="row" gap="small" margin={{top: 'small'}}>
                <Button
                  disabled={!passwordValid}
                  loading={submitting}
                  submit
                  primary
                >
                  Change password
                </Button>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default compose(
  graphql(
    gql`
      query TokenQuery($token: String!) {
        validatePasswordToken(token: $token) {
          valid
          error
        }
      }
    `,
    {
      name: 'tokenQuery'
    }
  ),
  graphql(
    gql`
      mutation PasswordMutation($token: String!, $password: String!) {
        newPassword(token: $token, password: $password)
      }
    `,
    {
      name: 'passwordMutation'
    }
  )
)(withRouter(NewPasswordForm));
