import React, {useEffect, useState} from 'react';

import {DataTable, Box} from 'grommet';
import githubRelease from 'github-latest-release';

import {Button, Title} from '..';

import Card from '../Card';

const wantedPlaftorm = ['win', 'mac', 'ubuntu', 'redhat'];

const columns = [
  {
    property: 'os',
    primary: true
  },
  {
    property: 'version'
  },
  {
    property: 'link',
    align: 'end',
    render: (data) =>
      data && (
        <Button external url={data.link}>
          Download
        </Button>
      )
  }
];

const ClientsList = () => {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    const getLastRelease = async () => {
      const data = await githubRelease('VPNht', 'desktop');
      if (data && data.tag_name) {
        // remove v from v1.3.1
        setVersion(data.tag_name.substr(1));
      }
    };
    getLastRelease();
  }, []);

  const realData = wantedPlaftorm.map((platform) => {
    return {
      os:
        platform === 'win'
          ? 'Windows'
          : platform === 'mac'
          ? 'Mac OS X'
          : platform === 'ubuntu'
          ? 'Ubuntu'
          : 'Redhat',
      version: `v${version}`,
      link: `https://github.com/VPNht/desktop/releases/download/v${version}/VPNht-1.1.2.${
        platform === 'win'
          ? 'exe'
          : platform === 'mac'
          ? 'pkg'
          : platform === 'ubuntu'
          ? 'deb'
          : 'rpm'
      }`
    };
  });

  return (
    <Box margin={{top: 'medium'}}>
      <Title>VPN Softwares</Title>
      <Box
        gap="medium"
        direction="row-responsive"
        justify="center"
        align="start"
      >
        <Card>
          <DataTable columns={columns} data={realData} size="medium" />
        </Card>
      </Box>
    </Box>
  );
};

export default ClientsList;
