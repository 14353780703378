import React from 'react';
import {Grommet} from 'grommet';

import {grommet} from 'grommet/themes';

import {deepMerge} from 'grommet/utils';

import {brand as brandGreen, darkGrey} from '../../utils/colors';
import BuyVPNForm from './BuyVPNForm';

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: brandGreen,
      'dark-1': '#2b2b2b',
      'dark-2': darkGrey,
      'accent-1': '#00bab8',
      'accent-2': '#FEAD9A'
    },
    font: {
      family: 'Roboto',
      size: '14px'
    }
  },
  button: {
    border: {
      radius: '4px'
    }
  }
});

const BuyVPNPage = ({processor, plan}) => {
  return (
    <Grommet theme={theme}>
      <BuyVPNForm processor={processor} plan={plan} />
    </Grommet>
  );
};

export default BuyVPNPage;
