import React, {Component} from 'react';

import * as PropTypes from 'prop-types';

import {Grommet} from 'grommet';

import {Container, Content, Nav} from '..';
import {grommet} from 'grommet/themes';

import {deepMerge} from 'grommet/utils';
import {THEME_CONFIG} from '../../config';

class AppFrame extends Component {
  state = {
    isSideFolded: true,
    isMobileNavFolded: true
  };

  onSideToggle = () => {
    this.setState({
      isSideFolded: !this.state.isSideFolded,
      isMobileNavFolded: true
    });
  };

  onMobileNavToggle = () => {
    this.setState((prevState) => ({
      isMobileNavFolded: !prevState.isMobileNavFolded,
      isSideFolded: true
    }));
  };

  toggleState = (key) => {
    return () => {
      this.setState((prevState) => ({[key]: !prevState[key]}));
    };
  };

  handleSearchFieldChange = (value) => {
    this.setState({searchText: value});
    if (value.length > 0) {
      this.setState({searchActive: true});
    } else {
      this.setState({searchActive: false});
    }
  };

  handleSearchResultsDismiss = () => {
    this.setState(() => {
      return {
        searchActive: false,
        searchText: ''
      };
    });
  };

  render() {
    const {children, showSideBar, full, width} = this.props;
    const {isSideFolded, isMobileNavFolded} = this.state;

    let defaultWidth = 1024;

    if (!showSideBar) {
      defaultWidth = 612;
    }

    if (width) {
      defaultWidth = width;
    }

    return (
      <Grommet theme={THEME_CONFIG}>
        <Container showSideNav={showSideBar}>
          {showSideBar && (
            <Nav
              isSideFolded={isSideFolded}
              isMobileNavFolded={isMobileNavFolded}
              showSideNav={showSideBar}
              onSideToggle={this.onSideToggle}
              onMobileNavToggle={this.onMobileNavToggle}
            />
          )}
          <Content
            moveRight={!isSideFolded}
            full={full}
            width={defaultWidth}
            data-e2e-id="content"
          >
            {children}
          </Content>
        </Container>
      </Grommet>
    );
  }
}

export default AppFrame;
