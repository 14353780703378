import React from 'react';
import {Paypal, CreditCard, Bitcoin, Atm} from 'grommet-icons';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import {Box, Text, Button} from 'grommet';
import PayBox from './PayBox';

const PayInvoice = ({invoiceId, deleteInvoiceMutation, history}) => {
  const onClickDeleteInvoice = async () => {
    await deleteInvoiceMutation({
      variables: {invoiceId}
    });

    history.push('/invoices');
  };

  return (
    <Box justify="center" align="center">
      <Text size="xlarge" margin={{top: 'medium'}} color="dark-2">
        Select payment method
      </Text>
      <Box
        fill
        direction="row-responsive"
        justify="center"
        align="center"
        pad="medium"
        gap="medium"
      >
        <PayBox
          invoiceId={invoiceId}
          text="Paypal"
          processor="PAYPAL"
          icon={<Paypal size="large" />}
        />
        <PayBox
          invoiceId={invoiceId}
          text="Credit Card"
          processor="PAYMENTWALL"
          icon={<CreditCard size="large" />}
          subscribe
        />
        <PayBox
          invoiceId={invoiceId}
          text="Coin Payments"
          processor="CRYPTO"
          icon={<Bitcoin size="large" />}
          support="BTC, LTC and more..."
        />
      </Box>
      <Box
        fill
        direction="row-responsive"
        justify="center"
        align="center"
        pad="medium"
        gap="medium"
      >
        <PayBox
          invoiceId={invoiceId}
          text="Bank Transfer"
          processor="PAYMENTWALL"
        />
        <PayBox invoiceId={invoiceId} text="iDEAL" processor="PAYMENTWALL" />
        <PayBox
          invoiceId={invoiceId}
          text="MisterCash"
          processor="PAYMENTWALL"
        />
      </Box>
      <Button
        size="small"
        margin={{top: 'small', bottom: 'small'}}
        color="dark-2"
        onClick={onClickDeleteInvoice}
      >
        Delete this invoice
      </Button>
    </Box>
  );
};

export default graphql(
  gql`
    mutation DeleteInvoiceMutation($invoiceId: ID!) {
      deleteInvoice(invoiceId: $invoiceId)
    }
  `,
  {
    name: 'deleteInvoiceMutation'
  }
)(withRouter(PayInvoice));
