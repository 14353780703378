import React from 'react';
import {Select, Box, Text} from 'grommet';
import {Notification} from 'grommet-icons';

import styled from 'styled-components';
import {paleGrey, brand as brandGreen} from '../../utils/colors';

const StyledSelect = styled(Select)`
  border-color: ${paleGrey};
  margin-top: '0.5em';
  :focus {
    border-color: ${brandGreen};
    border-width: 0.2em;
  }
`;

const SelectComponent = ({
  onChange,
  value,
  options,
  name,
  placeholder,
  error,
  margin = true,
  ...rest
}) => {
  const StyledContainer = styled.div`
    margin-top: ${margin ? '1rem' : 'none'};
  `;

  return (
    <StyledContainer>
      <StyledSelect
        id={name}
        name={name}
        value={options.find((option) => option.id === value).name}
        options={options}
        children={({name}) => name}
        onChange={(event) => {
          onChange(event.value.id);
        }}
        {...rest}
      />
      {error ? (
        <Box
          direction="row-responsive"
          gap="small"
          margin={{top: 'xsmall'}}
          align="start"
        >
          <Box>
            <Notification color="#bf0711" size="18px" />
          </Box>
          <Text color="#bf0711" size="xsmall">
            {error}
          </Text>
        </Box>
      ) : null}
    </StyledContainer>
  );
};

export default SelectComponent;
