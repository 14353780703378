import lighten from 'polished/lib/color/lighten';

export const brand = '#00a6a3';

export const paleGrey = '#dfe3e8';
export const mainGrey = '#f4f6f8';
export const darkGrey = '#5a5a5a';

export const lightGrey = 'rgba(20, 20, 20, 0.1)';
export const grey = '#282a36';

export const red = '#ff5555';
export const violetRed = 'rgb(219, 112, 147)';
export const lightVioletRed = lighten(0.31, 'rgb(219, 112, 147)');
