import React from 'react';
import {DashboardPage, AppWrapper} from '../components';

const DashboardRoute = () => {
  return (
    <AppWrapper fullWidth>
      <DashboardPage />
    </AppWrapper>
  );
};

export default DashboardRoute;
