import React, {useContext} from 'react';
import {DataTable, ResponsiveContext} from 'grommet';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {Button, SkeletonBodyText} from '..';

import Card from '../Card';

const InvoicesCard = ({invoicesQuery}) => {
  const {loading, getInvoices} = invoicesQuery;
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';

  let columns = [
    {
      property: 'description',
      primary: true
    },
    {
      property: 'date'
    },
    {
      property: 'status'
    },
    {
      property: 'actions',
      align: 'end',
      render: (data) =>
        data && (
          <Button
            primary={data.status !== 'PAID'}
            url={`/invoice/${data.actions}`}
          >
            {data.status === 'PAID' ? 'View' : 'Pay'}
          </Button>
        )
    }
  ];

  if (isMobile) {
    columns = [
      {
        property: 'date',
        primary: true
      },
      {
        property: 'actions',
        align: 'end',
        render: (data) =>
          data && (
            <Button
              primary={data.status !== 'PAID'}
              url={`/invoice/${data.actions}`}
            >
              {data.status === 'PAID' ? 'View' : 'Pay'}
            </Button>
          )
      }
    ];
  }

  const data = [];
  if (!loading && getInvoices) {
    getInvoices.forEach((invoice) => {
      if (invoice.status === 'CANCELLED') {
        return;
      }

      data.push({
        description: `Invoice #${invoice.id}`,
        date: invoice.date,
        status: invoice.status,
        actions: invoice.id
      });
    });
  }

  return (
    <Card>
      {loading ? (
        <SkeletonBodyText lines="5" />
      ) : data.length > 0 ? (
        <DataTable columns={columns} data={data} size="100%" />
      ) : (
        'No invoices found.'
      )}
    </Card>
  );
};

export default graphql(
  gql`
    query InvoicesQuery {
      getInvoices {
        id
        date
        status
      }
    }
  `,
  {
    name: 'invoicesQuery'
  }
)(InvoicesCard);
