import React, {useState} from 'react';
import {Box, Text} from 'grommet';
import {Trash} from 'grommet-icons';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {Button} from '..';

const ConfirmDeletion = ({cancelActiveServiceMutation}) => {
  const [isWorking, setWorking] = useState(false);
  const [isError, setError] = useState(false);
  return (
    <div style={{marginTop: '1em'}}>
      {isError ? (
        <Text size="medium" color="red">
          {isError}
        </Text>
      ) : (
        <Text size="medium">
          Are you sure you want to cancel your account ?
        </Text>
      )}

      <Box direction="row" gap="small" margin={{top: 'small'}}>
        <Button
          primary
          loading={isWorking}
          icon={<Trash />}
          label="Cancel account"
          onClick={async () => {
            setWorking(true);
            try {
              await cancelActiveServiceMutation();
              setError('Cancellation request submitted successfully');
            } catch (error) {
              setError(error.graphQLErrors[0].message);
            } finally {
              setWorking(false);
            }
          }}
        />
      </Box>
      <p>
        <Text size="xsmall">
          * Your account will stay active until the subscription expire.
        </Text>
      </p>
    </div>
  );
};

export default graphql(
  gql`
    mutation CancelActiveServiceMutation {
      cancelActiveService
    }
  `,
  {
    name: 'cancelActiveServiceMutation'
  }
)(ConfirmDeletion);
