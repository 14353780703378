import React from 'react';
import {Page} from '..';
import KBCard from './KBCard';

const KBPage = ({kbId}) => {
  return (
    <Page>
      <KBCard kb={kbId} />
    </Page>
  );
};

export default KBPage;
