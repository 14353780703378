import React from 'react';
import styled from 'styled-components';
import {Box} from 'grommet';
import rem from '../utils/rem';
import {paleGrey, darkGrey} from '../utils/colors';

const StyledBox = styled(Box)`
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding: ${rem(5)} ${rem(10)};
  border: ${rem(3)} solid #fff;
  border-radius: ${rem(15)};
  font-size: ${rem(15)};
  line-height: ${rem(20)};
  color: ${darkGrey};
  background-color: ${paleGrey};
`;

const Badge = ({children}) => {
  return <StyledBox>{children}</StyledBox>;
};

export default Badge;
