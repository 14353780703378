import React from 'react';
import {InvoicesPage, AppWrapper} from '../components';

const InvoicesRoute = () => {
  return (
    <AppWrapper>
      <InvoicesPage />
    </AppWrapper>
  );
};

export default InvoicesRoute;
