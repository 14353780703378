import React from 'react';
import {TicketPage, AppWrapper} from '../components';

const TicketRoute = ({match}) => {
  return (
    <AppWrapper>
      <TicketPage ticketId={match.params.ticketId} />
    </AppWrapper>
  );
};

export default TicketRoute;
