import React, {useEffect, useState, useContext} from 'react';
import {withRouter} from 'react-router-dom';

import {DataTable, Box, Button, ResponsiveContext} from 'grommet';
import {License, Trash, Basket} from 'grommet-icons';

import gql from 'graphql-tag';
import {graphql, compose} from 'react-apollo';

import {Title, Select} from '..';

import Card from '../Card';

const ClientsList = ({serviceMutation, createSubMutation, history}) => {
  const [isLoading, setLoading] = useState(false);
  const [service, setService] = useState(false);
  const [plan, setPlan] = useState('MONTHLY');
  const [processor, setProcessor] = useState('PAYPAL');
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';

  useEffect(() => {
    const getAccountDetails = async () => {
      const {data} = await serviceMutation();
      setService(data.service);
    };
    getAccountDetails();
  }, []);

  const columns = [
    {
      property: 'key',
      primary: true,
      render: (data) =>
        data.key && data.key.charAt(0).toUpperCase() + data.key.slice(1)
    },
    {
      property: 'value',
      render: (data) => {
        if (data.key === 'plan') {
          return (
            <Select
              margin={false}
              label="Plan"
              value={plan}
              onChange={(value) => {
                setPlan(value);
              }}
              options={[
                {name: 'Monthly', id: 'MONTHLY'},
                {name: 'Annually', id: 'ANNUALLY'}
              ]}
            />
          );
        }

        if (data.key === 'processor') {
          return (
            <Select
              margin={false}
              label="Processor"
              value={processor}
              onChange={(value) => {
                setProcessor(value);
              }}
              options={[
                {name: 'Paypal', id: 'PAYPAL'},
                {name: 'Credit Card', id: 'PAYMENTWALL'},
                {name: 'Crypto', id: 'CRYPTO'},
                {name: 'Bank transfer', id: 'BANK'},
                {name: 'iDeal', id: 'IDEAL'},
                {name: 'MisterCash', id: 'MISTERCASH'},
                {name: 'Others', id: 'OTHERS'}
              ]}
            />
          );
        }

        return data.value;
      }
    }
  ];

  return (
    <Box margin={{top: 'medium'}}>
      <Title>VPN Account</Title>
      <Box
        gap="medium"
        direction="row-responsive"
        fill="horizontal"
        align="start"
      >
        {service ? (
          <Box
            flex={!isMobile}
            fill={!isMobile}
            direction={!isMobile ? 'row' : 'column'}
            align="start"
            gap="medium"
            margin={{top: 'small'}}
          >
            <Card>
              <DataTable
                columns={columns}
                data={[
                  {key: 'status', value: 'Active'},
                  {key: 'expiration', value: service.nextduedate}
                ]}
                size="medium"
              />
              <div style={{marginLeft: '1em'}}>
                <Button
                  size="small"
                  primary
                  color="#8b0000"
                  icon={<Trash />}
                  label="Cancel account"
                  onClick={() => {
                    history.push('/delete-account');
                  }}
                />
              </div>
            </Card>
            <Card>
              <DataTable
                columns={columns}
                data={[
                  {key: 'username', value: service.username},
                  {key: 'password', value: '******'}
                ]}
                size="medium"
              />
              <div style={{marginLeft: '1em'}}>
                <Button
                  size="small"
                  primary
                  color="#000"
                  icon={<License />}
                  label="Change password"
                  onClick={() => {
                    history.push('/change-password');
                  }}
                />
              </div>
            </Card>
          </Box>
        ) : (
          <Box
            flex={!isMobile}
            fill={!isMobile}
            direction={!isMobile ? 'row' : 'column'}
            align="start"
            gap="medium"
            margin={{top: 'small'}}
          >
            <Card>
              <DataTable
                columns={columns}
                data={[
                  {key: 'plan', value: 'N/A'},
                  {key: 'processor', value: 'N/A'}
                ]}
                size="medium"
              />
              <div style={{marginLeft: '1em'}}>
                <Button
                  size="small"
                  primary
                  loading={isLoading}
                  disabled={isLoading}
                  color="#013220"
                  icon={<Basket />}
                  label="Subscribe"
                  onClick={async () => {
                    setLoading(true);
                    let realProcessor = processor;
                    if (processor !== 'PAYPAL' && processor !== 'CRYPTO') {
                      realProcessor = 'PAYMENTWALL';
                    }

                    const {data} = await createSubMutation({
                      variables: {
                        subscription: {
                          plan,
                          processor: realProcessor
                        }
                      }
                    });

                    if (
                      data &&
                      data.createSubscriptionCust &&
                      data.createSubscriptionCust.url
                    ) {
                      window.location.href = data.createSubscriptionCust.url;
                    }
                  }}
                />
              </div>
            </Card>
            <Card>
              <DataTable
                columns={columns}
                data={[
                  {key: 'username', value: 'N/A'},
                  {key: 'password', value: 'N/A'}
                ]}
                size="medium"
              />
              <div style={{marginLeft: '1em'}}>
                <Button
                  disabled
                  size="small"
                  primary
                  color="#000"
                  icon={<License />}
                  label="Change password"
                />
              </div>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default compose(
  graphql(
    gql`
      mutation ServiceMutation {
        service {
          id
          orderid
          username
          billingcycle
          nextduedate
        }
      }
    `,
    {
      name: 'serviceMutation'
    }
  ),
  graphql(
    gql`
      mutation SubscriptionMutation($subscription: SubscriptionCustInput!) {
        createSubscriptionCust(subscription: $subscription) {
          url
        }
      }
    `,
    {
      name: 'createSubMutation'
    }
  )
)(withRouter(ClientsList));
