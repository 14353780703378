import React, {Component} from 'react';
import {Page, Card, Button} from '..';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import NewPasswordForm from './NewPasswordForm';
import SendPasswordForm from './SendPasswordForm';

class ChangePasswordPage extends Component {
  state = {
    token: false
  };

  componentDidMount() {
    const {token} = queryString.parse(this.props.history.location.search);
    this.setState({token});
  }

  render() {
    const {token} = this.state;
    return (
      <Page title="Change password.">
        <Card>
          {token ? <NewPasswordForm token={token} /> : <SendPasswordForm />}
        </Card>
        <Button
          url="/"
          size="small"
          plain
          margin={{top: 'medium', left: 'small'}}
        >
          ← Back home...
        </Button>
      </Page>
    );
  }
}
export default withRouter(ChangePasswordPage);
