import React, {Component} from 'react';
import FormState from '@shopify/react-form-state';
import {Box} from 'grommet';
import {TextInput, Button, Banner} from '..';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

class LoginForm extends Component {
  onSubmitLoginForm = async ({fields: {email, password}}) => {
    const {loginMutation, history} = this.props;

    try {
      const {data} = await loginMutation({
        variables: {email: email.value, password: password.value}
      });

      if (data.login && data.login.success) {
        window.localStorage.setItem('jwt', data.login.token);
        const params = queryString.parse(history.location.search);

        if (params && params.from) {
          history.push(params.from);
        } else {
          history.push('/');
        }
        console.log(params);
      } else {
        // return error message
        return [{message: 'Unable to validate credentials'}];
      }
    } catch (error) {
      return error.graphQLErrors;
    }
  };

  render() {
    let externalBanner = false;
    if (this.props.location.state) {
      externalBanner = this.props.location.state.banner;
    }

    return (
      <FormState
        validateOnSubmit
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={this.onSubmitLoginForm}
        validators={{
          email(input) {
            if (input === '') {
              return "Username or e-mail can't be blank";
            }
            return null;
          },
          password(input) {
            if (input === '') {
              return "Password can't be blank";
            }
            return null;
          }
        }}
      >
        {({fields, submitting, errors, submit, valid, dirty}) => {
          console.log(errors);
          return (
            <form onSubmit={submit}>
              {!valid && errors.length > 0 ? (
                <Banner status="critical">
                  {errors.map((error) => error.message)}.
                </Banner>
              ) : externalBanner ? (
                <Banner status="critical">{externalBanner}.</Banner>
              ) : null}
              <TextInput
                label="E-mail"
                placeholder="Enter your username or e-mail"
                {...fields.email}
              />
              <TextInput
                label="Password"
                placeholder="Enter your password"
                type="password"
                {...fields.password}
              />
              <Box direction="row" gap="small" margin={{top: 'small'}}>
                <Button loading={submitting} submit primary>
                  Login
                </Button>
                <Button url="/change-password" color="dark-6" plain>
                  Reset password
                </Button>
              </Box>
            </form>
          );
        }}
      </FormState>
    );
  }
}

export default graphql(
  gql`
    mutation LoginMutation($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        success
        token
      }
    }
  `,
  {
    name: 'loginMutation'
  }
)(withRouter(LoginForm));
