import React from 'react';
import {Page} from '..';
import ClientsList from './ClientsList';

const HelpdeskPage = () => {
  return (
    <Page title="Download VPN clients">
      <ClientsList />
    </Page>
  );
};

export default HelpdeskPage;
