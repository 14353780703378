import React from 'react';
import {TableRow, TableCell, Text} from 'grommet';

const LineItem = ({title, price}) => {
  return (
    <TableRow key="1">
      <TableCell key="description" scope="row">
        <Text size="medium" color="dark-3">
          {title}
        </Text>
      </TableCell>
      <TableCell key="amount" align="end">
        <Text size="medium" color="dark-4">
          {price}
        </Text>
      </TableCell>
    </TableRow>
  );
};

export default LineItem;
