import React from 'react';
import {BuyVPNPage} from '../components';

const BuyVPNRoute = ({match}) => {
  return (
    <BuyVPNPage processor={match.params.processor} plan={match.params.plan} />
  );
};

export default BuyVPNRoute;
