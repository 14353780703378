import React from 'react';
import styled from 'styled-components';
import {brand as brandColor} from '../../utils/colors';

const StyledRibbon = styled.div`
  position: absolute;
  right: -5px;
  top: -22px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #fead9a;
    background: linear-gradient(#e68a75 0%, #fead9a 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
  span::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #fead9a;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead9a;
  }
  span::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #fead9a;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead9a;
  }
`;

const Ribbon = () => {
  return (
    <StyledRibbon>
      <div className="ribbon">
        <span>UNPAID</span>
      </div>
    </StyledRibbon>
  );
};

export default Ribbon;
